import dynamic from 'next/dynamic'

import { Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./ResourceHero').then(({ ResourceHero }) => ResourceHero)))
	),
	{
		type: 'resource-hero',
		label: 'Contentful/Resource/Resource Hero',
		props: {
			className: Style()
		}
	}
)
