import dynamic from 'next/dynamic'

import { Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./CPMCalculator').then(({ CPMCalculator }) => CPMCalculator)))
	),
	{
		type: 'cpmcalculator',
		label: 'CPM Calculator',
		props: {
			className: Style(),
			dataTestId: TextInput({
				label: 'dataTestId',
				defaultValue: 'CPMCalculator'
			})
		}
	}
)
