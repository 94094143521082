import dynamic from 'next/dynamic'

import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Breadcrumbs/Breadcrumbs.makeswift'

const { ...rest } = props

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./ThemeBreadcrumbs').then(({ ThemeBreadcrumbs }) => ThemeBreadcrumbs))
		)
	),
	{
		type: 'marketplace-theme-breadcrumbs',
		label: 'Contentful/Marketplace/Themes/Theme Breadcrumbs',
		props: rest
	}
)
