import dynamic from 'next/dynamic'

import { Color, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./ToolTip').then(({ ToolTip }) => ToolTip)))
	),
	{
		type: 'tool-tip',
		label: 'Tool Tip Monogram',
		props: {
			className: Style({ properties: [Style.TextStyle] }),
			color: Color({ defaultValue: '#121118' }),
			text: TextInput({ label: 'ToolTip Text', selectAll: true }),
			toolTipName: TextInput({ selectAll: true, label: 'Accesibility Name (#id)' })
		}
	}
)
