import dynamic from 'next/dynamic'

import { Color, List, Number, Select, Shape, Slot, Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	bgColor: Color({ label: 'Background', defaultValue: '#fff' }),
	className: Style({
		properties: [Style.Width, Style.Margin, Style.Padding, Style.Border, Style.BorderRadius]
	}),
	pixelHeight: Number({
		label: 'Height (px)',
		defaultValue: 0
	}),
	xOverflow: Select({
		label: 'Horizontal Overflow ',
		options: [
			{ value: 'overflow-x-auto', label: 'Auto' },
			{ value: 'overflow-x-hidden', label: 'Hidden' },
			{ value: 'overflow-x-scroll', label: 'Scroll' },
			{ value: 'overflow-x-hidden hover:overflow-x-scroll', label: 'Auto Hide' },
			{ value: 'overflow-x-visible', label: 'Visible' }
		],
		defaultValue: 'overflow-x-auto'
	}),
	yOverflow: Select({
		label: 'Vertical Overflow ',
		options: [
			{ value: 'overflow-y-auto', label: 'Auto' },
			{ value: 'overflow-y-hidden', label: 'Hidden' },
			{ value: 'overflow-y-scroll', label: 'Scroll' },
			{ value: 'overflow-y-hidden hover:overflow-y-scroll', label: 'Auto Hide' },
			{ value: 'overflow-y-visible', label: 'Visible' }
		],
		defaultValue: 'overflow-y-auto'
	}),

	innerFade: Shape({
		type: {
			type: Select({
				label: 'Inner Fade',
				options: [
					{ label: 'None', value: 'hidden' },
					{ label: 'All', value: 'block' },
					{ label: 'Start at XLarge', value: 'block xl:hidden' },
					{ label: 'Start at Large', value: 'block lg:hidden' },
					{ label: 'Start at Medium', value: 'block md:hidden' }
				],
				defaultValue: 'hidden'
			}),
			size: Number({ label: 'Fade Width', defaultValue: 24 }),
			transition: Number({ label: 'Fade Opacity', defaultValue: 96, min: 0, max: 100 }),
			color: Color({ label: 'Fade Color', defaultValue: '#fff' })
		}
	}),
	shadows: List({
		label: 'Shadows',
		type: Shape({
			type: {
				shadowType: Select({
					label: 'Type',
					labelOrientation: 'horizontal',
					options: [
						{ label: 'Drop shadow', value: 'drop' },
						{ label: 'Inner shadow', value: 'inset' }
					],
					defaultValue: 'drop'
				}),
				color: Color({ label: 'Color', defaultValue: '#000' }),
				distance: Number({ label: 'Distance', defaultValue: 2 }),
				blur: Number({ label: 'Blur', defaultValue: 4 }),
				spread: Number({ label: 'Spread', defaultValue: 0 })
			}
		}),
		getItemLabel() {
			return 'Shadow layer '
		}
	}),
	children: Slot()
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./OverflowBox').then(({ OverflowBox }) => OverflowBox)))
	),
	{
		type: 'OverflowBox',
		label: 'Overflow Box',
		props
	}
)
