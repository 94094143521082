import dynamic from 'next/dynamic'

import { Image, Slot, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	image: Image({ format: Image.Format.WithDimensions }),
	alt: TextInput({ label: 'Alt Text', defaultValue: 'Image Description', selectAll: true }),
	content: Slot()
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./CardImageFullWidth').then(({ CardImageFullWidth }) => CardImageFullWidth))
		)
	),
	{ type: 'CardImageFullWidth', label: 'Card Image (Full Width)', props }
)
