import dynamic from 'next/dynamic'

import { Combobox, Select } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulRichText/ContentfulRichText.makeswift'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./ResourceFormRichText').then(({ ResourceFormRichText }) => ResourceFormRichText)
			)
		)
	),
	{
		type: 'resource-form-rich-text',
		label: 'Contentful/Resource/Resource Form Rich Text',
		props: {
			...props,
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'Resource',
						filter: (name) => name === 'RichText',
						query
					})
				}
			}),
			color: Select({
				label: 'Color',
				options: [
					{ value: '', label: 'Base' },
					{ value: 'prose-p:text-black-100', label: 'Black' },
					{ value: 'prose-p:text-white', label: 'White' },
					{ value: 'prose-p:text-gray-700', label: 'Gray' }
				],
				defaultValue: ''
			})
		}
	}
)
