import dynamic from 'next/dynamic'

import { Color, Select, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./ToolTipSb').then(({ ToolTipSb }) => ToolTipSb)))
	),
	{
		type: 'tool-tip-sb',
		label: 'Tool Tip',
		props: {
			className: Style({
				properties: [Style.Margin, Style.TextStyle]
			}),
			color: Color({ label: 'Text Color', defaultValue: '#FFF' }),
			bgColor: Color({ label: 'Background Color', defaultValue: '#121118' }),
			text: TextInput({ label: 'Text', selectAll: true }),
			tooltipText: TextInput({ label: 'Tooltip Text', selectAll: true }),
			alignment: Select({
				label: 'Tooltip Container Alignment',
				options: [
					{ value: '-left-2', label: 'Start' },
					{ value: '-left-32', label: 'Center' },
					{ value: '-right-2', label: 'End' }
				],
				defaultValue: '-left-2'
			})
		}
	}
)
