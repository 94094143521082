import dynamic from 'next/dynamic'

import {
	Checkbox,
	Color,
	Image,
	Link,
	Select,
	Style,
	TextArea,
	TextInput
} from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) => dynamic(() => patch(import('./Card').then(({ Card }) => Card)))),
	{
		type: 'card',
		label: 'Card',
		props: {
			className: Style(),
			isContained: Checkbox({ label: 'Contained card', defaultValue: true }),
			isFeatured: Checkbox({ label: 'Featured card' }),
			imageSrc: Image({ label: 'Image', format: Image.Format.WithDimensions }),
			imageAlt: TextInput({
				label: 'Alt text',
				defaultValue: 'Image',
				selectAll: true
			}),
			imageRatio: Select({
				label: 'Image aspect ratio',
				options: [
					{ value: 'none', label: 'None' },
					{ value: 'video', label: '16:9' },
					{ value: 'square', label: 'Square' },
					{ value: '4/3', label: '4:3' }
				],
				defaultValue: 'none'
			}),
			heading: TextInput({
				label: 'Heading',
				defaultValue: 'Case study',
				selectAll: true
			}),
			title: TextInput({
				label: 'Title',
				defaultValue: 'This is a title',
				selectAll: true
			}),
			text: TextArea({
				label: 'Text',
				defaultValue:
					'Lorem ipsum dolor sit amet, vix id suas harum tacimates, enim doming prompta vim antial.',
				selectAll: true
			}),
			linkText: TextInput({
				label: 'Button text',
				defaultValue: 'Read more',
				selectAll: true
			}),
			linkURL: Link({ label: 'Button action' }),
			bgColor: Color({ label: 'Card color', defaultValue: '#121118' }),
			variant: Select({
				label: 'Text color',
				options: [
					{ value: 'light', label: 'White' },
					{ value: 'dark', label: 'Black' }
				],
				defaultValue: 'light'
			}),
			verticalContentAlignment: Select({
				label: 'Vertical content alignment',
				options: [
					{ value: 'top', label: 'Top' },
					{ value: 'between', label: 'Between' }
				],
				defaultValue: 'between'
			})
		}
	}
)
