import dynamic from 'next/dynamic'

import { Checkbox, Image, Select, Slot, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	image: Image({ format: Image.Format.WithDimensions }),
	alt: TextInput({ label: 'Alt Text', defaultValue: 'Image Description', selectAll: true }),
	content: Slot(),
	contentPosition: Select({
		label: 'Content Position',
		options: [
			{ value: 'top', label: 'Top' },
			{ value: 'center', label: 'Center' },
			{ value: 'bottom', label: 'Bottom' }
		],
		defaultValue: 'top'
	}),
	flip: Checkbox({ label: 'Flip' })
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./TwelveColumnCard').then(({ TwelveColumnCard }) => TwelveColumnCard))
		)
	),
	{ type: 'TwelveColumnCard', label: 'Twelve Column Card', props }
)
