import dynamic from 'next/dynamic'

import { Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./PricingTableHardCoded').then(({ PricingTableHardCoded }) => PricingTableHardCoded)
			)
		)
	),
	{
		type: 'pricing-table',
		label: 'Pricing Table',
		props: {
			className: Style()
			// pricingToggle: Shape({
			// 	type: {
			// 		monthlyPricing: Shape({
			// 			type: {
			// 				label: TextInput({ label: 'Monthly Pricing Toggle Label', defaultValue: 'Monthly' })
			// 			}
			// 		}),
			// 		annualPricing: Shape({
			// 			type: {
			// 				label: TextInput({
			// 					label: 'Annual Pricing Toggle Label',
			// 					defaultValue: 'Annual (Save 25%)'
			// 				})
			// 			}
			// 		})
			// 	}
			// }),

			// pricingDisclaimerText: TextInput({ label: 'Pricing Disclaimer Text' }),

			// pricingPlans: Shape({
			// 	label: 'Pricing Plans',
			// 	type: {
			// 		plans: List({
			// 			label: 'Pricing Plans',
			// 			getItemLabel(row: { planName?: string } | undefined) {
			// 				return row?.planName || 'Pricing Plan'
			// 			},
			// 			type: Shape({
			// 				type: {
			// 					isFeatured: Checkbox({ label: 'Is Featured Plan', defaultValue: false }),
			// 					featuredPlanLabel: TextInput({ label: 'Featured Plan Label' }),
			// 					planName: TextInput({ label: 'Plan Name' }),
			// 					tagline: TextInput({ label: 'Plan Tagline' }),
			// 					additionalMobileTagline: TextInput({ label: 'Additional Mobile Plan Tagline' }),
			// 					monthlyPrice: Number({
			// 						label: 'Monthly price'
			// 					}),
			// 					annualPrice: Number({
			// 						label: 'Annual price'
			// 					}),
			// 					mobileList: List({
			// 						label: 'Mobile List',
			// 						getItemLabel(listItem: { text?: string } | undefined) {
			// 							return listItem?.text || 'Mobile List Item'
			// 						},
			// 						type: Shape({
			// 							type: {
			// 								text: TextInput({ label: 'Mobile List Item text' })
			// 							}
			// 						})
			// 					}),
			// 					children: Slot()
			// 				}
			// 			})
			// 		})
			// 	}
			// }),

			// rows: List({
			// 	label: 'Rows',
			// 	getItemLabel(row: { label?: string } | undefined) {
			// 		return row?.label || 'Row'
			// 	},
			// 	type: Shape({
			// 		type: {
			// 			label: TextInput({ label: 'Row Label', defaultValue: 'Row' }),
			// 			rowHeaderChildren: Slot(),
			// 			rowHeader: Shape({
			// 				type: {
			// 					emptyCell: Checkbox({ label: 'Use Empty Row Header', defaultValue: false })
			// 				}
			// 			}),
			// 			rowType: Select({
			// 				label: 'Row type',
			// 				options: [
			// 					{
			// 						label: 'Deault',
			// 						value: 'default'
			// 					},
			// 					{
			// 						label: 'Heading',
			// 						value: 'heading'
			// 					}
			// 				],
			// 				defaultValue: 'default'
			// 			}),

			// 			cells: List({
			// 				label: 'Cells',
			// 				getItemLabel(cell: { label?: string } | undefined) {
			// 					return cell?.label || 'Cell'
			// 				},
			// 				type: Shape({
			// 					type: {
			// 						label: TextInput({ label: 'Cell Label', defaultValue: 'Cell' }),
			// 						emptyCell: Checkbox({ label: 'Use Empty Cell', defaultValue: false }),
			// 						checkmark: Checkbox({ label: 'Checkmark', defaultValue: false }),
			// 						children: Slot()
			// 					}
			// 				})
			// 			})
			// 		}
			// 	})
			// })
		}
	}
)
