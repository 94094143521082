import dynamic from 'next/dynamic'

import { Checkbox, Combobox, Select, Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { BlogPostFeedCategoriesDocument, BlogPostFeedPersonsDocument } from '@/generated/contentful'

import { client } from '@/lib/contentful/client'
import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	hideCategories: Checkbox({ label: 'Hide categories' }),
	hideAuthors: Checkbox({ label: 'Hide authors' }),
	size: Select({
		label: 'Size',
		options: [
			{ value: 'medium', label: 'Medium' },
			{ value: 'large', label: 'Large' }
		],
		defaultValue: 'medium'
	}),
	categorySlug: Combobox({
		label: 'Category',
		async getOptions(query) {
			const { categoryCollection } = await client({ preview: true }).request(
				BlogPostFeedCategoriesDocument,
				{
					query
				}
			)

			return (categoryCollection?.items ?? [])
				.filter(Boolean)
				.filter((item) => (item?.linkedFrom?.blogPostCollection?.total ?? 0) > 0)
				.map((item) => ({
					id: item?.sys?.id ?? '',
					label: item?.name ?? '',
					value: item?.slug ?? ''
				}))
		}
	}),
	authorSlug: Combobox({
		label: 'Author',
		async getOptions(query) {
			const { personCollection } = await client({ preview: true }).request(
				BlogPostFeedPersonsDocument,
				{ query }
			)

			return (personCollection?.items ?? [])
				.filter(Boolean)
				.filter((item) => (item?.linkedFrom?.blogPostCollection?.total ?? 0) > 0)
				.map((item) => ({
					id: item?.sys?.id ?? '',
					label: item?.fullName ?? '',
					value: item?.slug ?? ''
				}))
		}
	})
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./BlogPostFeed').then(({ BlogPostFeed }) => BlogPostFeed)))
	),
	{
		type: 'blog-post-feed',
		label: 'Contentful/Blog/Blog Feed',
		props
	}
)
