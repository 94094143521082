import dynamic from 'next/dynamic'

import { Checkbox, Color, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./Badge').then(({ Badge }) => Badge)))
	),
	{
		type: 'badge',
		label: 'Badge',
		props: {
			className: Style({ properties: Style.All }),
			textColor: Color({
				label: 'Text Color',
				defaultValue: '#808080'
			}),
			fitContent: Checkbox({ label: 'Hug Content', defaultValue: false }),
			bgColor: Color({ label: 'Background Color', defaultValue: '#FFFFFF' }),
			content: TextInput({
				label: 'Badge Content',
				defaultValue: 'Badge Content'
			})
		}
	}
)
