import dynamic from 'next/dynamic'

import { Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

const props = {
	className: Style()
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./ThemeImagePreview').then(({ ThemeImagePreview }) => ThemeImagePreview))
		)
	),
	{
		type: 'marketplace-theme-image-preview',
		label: 'Contentful/Marketplace/Themes/Theme Image Preview',
		props
	}
)
