import dynamic from 'next/dynamic'

import { Image, List, Number, Shape, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	logos: List({
		label: 'Logos',
		type: Shape({
			type: {
				logoImage: Image({
					label: 'Logo',
					format: Image.Format.WithDimensions
				}),
				logoAlt: TextInput({ label: 'Logo alt text', defaultValue: 'Logo' }),
				logoWidth: Number({
					label: 'Width',
					defaultValue: 120,
					suffix: 'px'
				})
			}
		}),
		getItemLabel(logo) {
			return logo?.logoAlt || 'Logo'
		}
	}),
	gap: Number({ label: 'Gap', defaultValue: 24, suffix: 'px', step: 4 })
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./LogoGrid').then(({ LogoGrid }) => LogoGrid)))
	),
	{ type: 'LogoGrid', label: 'Logo Grid', props }
)
