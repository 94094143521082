import dynamic from 'next/dynamic'

import { Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

const props = {
	className: Style(),
	supportTitle: TextInput({
		label: 'Support Title',
		defaultValue: 'Support & Documentation'
	}),
	supportText: TextInput({
		label: 'Support Text',
		defaultValue: 'Documentation for this theme can be found here:'
	}),
	mainSiderbarTitle: TextInput({
		label: 'Main Sidebar Title',
		defaultValue: 'Featured:'
	}),
	secondarySiderbarTitle: TextInput({
		label: 'Secondary Sidebar Title',
		defaultValue: 'Optimized for:'
	})
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./ThemeDetailsSidebar').then(({ ThemeDetailsSidebar }) => ThemeDetailsSidebar))
		)
	),
	{
		type: 'marketplace-theme-details-sidebar',
		label: 'Contentful/Marketplace/Themes/Theme Detailed Sidebar',
		props
	}
)
