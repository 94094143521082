import dynamic from 'next/dynamic'

import { Number, Select, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./ResourcesFeed').then(({ ResourcesFeed }) => ResourcesFeed)))
	),
	{
		type: 'resources-feed',
		label: 'Contentful/Resource/Resource Feed',
		props: {
			className: Style(),
			type: Select({
				label: 'Type',
				options: [
					{ value: 'Webinar', label: 'Webinar' },
					{ value: 'Guide', label: 'Guide' },
					{ value: 'Report', label: 'Report' }
				],
				defaultValue: 'Webinar'
			}),
			CTALinkText: TextInput({
				label: 'CTA Link Text',
				defaultValue: 'Watch Now',
				selectAll: true
			}),
			count: Number({
				label: 'Amount of Items',
				defaultValue: 20,
				step: 1,
				min: 1,
				max: 100,
				selectAll: true
			})
		}
	}
)
