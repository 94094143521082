import dynamic from 'next/dynamic'

import { Checkbox, Select, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./PressFeed').then(({ PressFeed }) => PressFeed)))
	),
	{
		type: 'press-feed',
		label: 'Contentful/Press/Press Feed',
		props: {
			className: Style(),
			hideImage: Checkbox({ label: 'Hide image' }),
			type: Select({
				label: 'Type',
				options: [
					{ value: 'News', label: 'News' },
					{ value: 'Press Release', label: 'Release' }
				],
				defaultValue: 'Press Release'
			}),
			ctaText: TextInput({
				label: 'CTA Text',
				defaultValue: 'Continue Reading',
				selectAll: true
			})
		}
	}
)
