import dynamic from 'next/dynamic'

import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '../../BlogPost/BlogPostFeed/BlogPostFeed.makeswift'

const { ...rest } = props

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./CategoryBlogPostFeed').then(({ CategoryBlogPostFeed }) => CategoryBlogPostFeed)
			)
		)
	),
	{
		type: 'category-blog-post-feed',
		label: 'Contentful/Category/Category Blog Post Feed',
		props: rest
	}
)
