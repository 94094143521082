import dynamic from 'next/dynamic'

import { Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./WistiaChannel').then(({ WistiaChannel }) => WistiaChannel)))
	),
	{
		type: 'wistia-channel',
		label: 'Wistia Channel',
		props: {
			className: Style(),
			url: TextInput({
				label: 'Share URL',
				defaultValue: 'https://fast.wistia.com/embed/channel/dmlbcuw9qt',
				selectAll: true
			})
		}
	}
)
