import dynamic from 'next/dynamic'

import {
	Checkbox,
	Color,
	List,
	Number,
	Shape,
	Slot,
	Style,
	TextInput
} from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { FolderStructure } from '@/utils/makeswift/constants'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./StickyIndicator').then(({ StickyIndicator }) => StickyIndicator)))
	),
	{
		type: 'sticky-indicator',
		label: `${FolderStructure.UnderDevelopment}Sticky Indicator`,
		props: {
			className: Style({ properties: [Style.Width, Style.Margin, Style.Padding] }),
			textStyle: Style({ properties: [Style.TextStyle] }),
			isSticky: Checkbox({ label: 'Sticky' }),
			columnGap: Number({
				label: 'Column Gap',
				defaultValue: 4,
				step: 2,
				suffix: 'px',
				selectAll: true
			}),
			sections: List({
				label: 'Sections',
				type: Shape({
					type: {
						id: TextInput({ label: 'Section id', defaultValue: 'section-1' }),
						title: TextInput({ label: 'Section title', defaultValue: 'Title' }),
						className: Style({ properties: [Style.Width, Style.Margin, Style.Padding] }),
						children: Slot()
					}
				}),
				getItemLabel() {
					return 'Sections'
				}
			}),
			numberTextStyle: Style({ properties: [Style.TextStyle] }),
			numberColor: Color({
				label: 'Number color',
				defaultValue: '#64748b'
			}),
			dividerColor: Color({
				label: 'Divider color',
				defaultValue: '#e2e8f0'
			}),
			hoverBgColor: Color({
				label: 'Hover background color',
				defaultValue: '#eff6ff66'
			}),
			textColor: Color({
				label: 'Text color',
				defaultValue: '#121118'
			}),
			activeTextColor: Color({
				label: 'Active Text color',
				defaultValue: '#2563eb'
			}),
			activeBgColor: Color({
				label: 'Active background color',
				defaultValue: '#eff6ff'
			})
		}
	}
)
