import dynamic from 'next/dynamic'

import { Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./BlogPostSearch').then(({ BlogPostSearch }) => BlogPostSearch)))
	),
	{
		type: 'search-results',
		label: 'Contentful/Blog/Blog Search',
		props: {
			className: Style(),
			placeholder: TextInput({
				label: 'Placeholder',
				defaultValue: 'Search for...',
				selectAll: true
			})
		}
	}
)
