import dynamic from 'next/dynamic'

import { Combobox } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulText/ContentfulText.makeswift'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./PersonText').then(({ PersonText }) => PersonText)))
	),
	{
		type: 'person-text',
		label: 'Contentful/Person/Person Text',
		props: {
			...props,
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'Person',
						filter: (name) => name === 'String',
						query
					})
				}
			})
		}
	}
)
