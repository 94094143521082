export enum FileExtension {
	Txt = '.txt',
	Jpg = '.jpg',
	Png = '.png',
	Php = '.php',
	Pdf = '.pdf',
	Htm = '.htm',
	Html = '.html'
}

/**
 * @description gets a string and checks if it ends in a File Extension
 * @param filename
 * @returns boolean
 */
export const isFile = (filename: string): boolean => {
	const fileExtension = filename.slice(filename.lastIndexOf('.'))
	return Object.values(FileExtension).includes(fileExtension as FileExtension)
}
