import dynamic from 'next/dynamic'

import { Combobox } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulRichText/ContentfulRichText.makeswift'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./PersonRichText').then(({ PersonRichText }) => PersonRichText)))
	),
	{
		type: 'person-rich-text',
		label: 'Contentful/Person/Person Rich Text',
		props: {
			...props,
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'Person',
						filter: (name) => name === 'RichText',
						query
					})
				}
			})
		}
	}
)
