type RouteMap = Map<Locale, Map<string, string>>

export const routesMap: RouteMap = new Map([
	[
		'es-ES',
		new Map([
			['ejemplos', 'showcase'],
			['empresa', 'company'],
			['lideres', 'leaders'],
			['premios', 'awards'],
			['articulos', 'articles'],
			['prensa', 'press'],
			['noticias', 'news'],
			['lanzamientos', 'releases'],
			['recursos', 'resources'],
			['guias', 'guides'],
			['caso-de-estudio', 'case-study']
		])
	],
	[
		'es-MX',
		new Map([
			['ejemplos', 'showcase'],
			['empresa', 'company'],
			['lideres', 'leaders'],
			['premios', 'awards'],
			['articulos', 'articles'],
			['prensa', 'press'],
			['noticias', 'news'],
			['lanzamientos', 'releases'],
			['recursos', 'resources'],
			['guias', 'guides'],
			['caso-de-estudio', 'case-study']
		])
	],
	[
		'fr-FR',
		new Map([
			['vitrine', 'showcase'],
			['societe', 'company'],
			['equipe-de-direction', 'leaders'],
			['distinctions', 'awards'],
			['articles', 'articles'],
			['presse', 'press'],
			['actualites', 'news'],
			['sorties', 'releases'],
			['ressources', 'resources'],
			['etude-de-cas', 'case-study']
		])
	],
	[
		'it-IT',
		new Map([
			['vetrina', 'showcase'],
			['azienda', 'company'],
			['il-nostro-team', 'leaders'],
			['premi', 'awards'],
			['articoli', 'articles'],
			['stampa', 'press'],
			['notizie', 'news'],
			['comunicati', 'releases'],
			['risorse', 'resources'],
			['guide', 'guides'],
			['caso-studio', 'case-study']
		])
	],
	[
		'nb-NO',
		new Map([
			['vis-frem', 'showcase'],
			['selskap', 'company'],
			['ledere', 'leaders'],
			['priser', 'awards']
		])
	],
	[
		'da-DK',
		new Map([
			['fremvisning', 'showcase'],
			['firma', 'company'],
			['ledere', 'leaders'],
			['priser', 'awards']
		])
	],
	[
		'sv-SE',
		new Map([
			['exempel', 'showcase'],
			['foretag', 'company'],
			['ledare', 'leaders'],
			['utmarkelser', 'awards']
		])
	],
	[
		'nl-NL',
		new Map([
			['voorbeeld', 'showcase'],
			['bedrijf', 'company'],
			['leiders', 'leaders'],
			['prijzen', 'awards'],
			['artikelen', 'articles'],
			['pers', 'press'],
			['nieuws', 'news'],
			['berichten', 'releases'],
			['bronnen', 'resources'],
			['handleidingen', 'guides'],
			['casestudy', 'case-study']
		])
	],
	[
		'de-AT',
		new Map([
			['beispiel', 'showcase'],
			['unternehmen', 'company'],
			['management', 'leaders'],
			['awards', 'awards'],
			['artikel', 'articles'],
			['presse', 'press'],
			['neuigkeiten', 'news'],
			['pressemitteilungen', 'releases'],
			['ressourcen', 'resources'],
			['leitfaden', 'guides'],
			['fallstudie', 'case-study']
		])
	],
	[
		'de-DE',
		new Map([
			['beispiel', 'showcase'],
			['unternehmen', 'company'],
			['management', 'leaders'],
			['awards', 'awards'],
			['artikel', 'articles'],
			['presse', 'press'],
			['neuigkeiten', 'news'],
			['pressemitteilungen', 'releases'],
			['ressourcen', 'resources'],
			['leitfaden', 'guides'],
			['fallstudie', 'case-study']
		])
	]
])
