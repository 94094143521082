import dynamic from 'next/dynamic'

import { Link, List, Shape, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	extraSection: List({
		label: 'Sidebar Extra Section',
		type: Shape({
			type: {
				text: TextInput({ label: 'Text', defaultValue: 'Case Studies', selectAll: true }),
				link: Link({ label: 'On click' })
			}
		}),
		getItemLabel(link) {
			return link?.text || 'Link'
		}
	})
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./ShowcaseSidebar').then(({ ShowcaseSidebar }) => ShowcaseSidebar)))
	),
	{
		type: 'showcase-sidebar',
		label: 'Contentful/Showcase/Showcase Sidebar',
		props
	}
)
