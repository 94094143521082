import dynamic from 'next/dynamic'

import {
	Checkbox,
	Color,
	Image,
	Link,
	List,
	Number,
	Select,
	Shape,
	Style,
	TextInput
} from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { FolderStructure } from '@/utils/makeswift/constants'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./DropDown').then(({ DropDown }) => DropDown)))
	),
	{
		type: 'dropdown',
		label: `${FolderStructure.UnderDevelopment}/Dropdown`,
		props: {
			className: Style({ properties: [Style.Width, Style.Padding, Style.Margin] }),
			containerStyles: Style({ properties: [Style.Border, Style.BorderRadius, Style.TextStyle] }),
			textContainerColor: Color({
				label: 'Text container',
				defaultValue: '#121118'
			}),
			hoverContainerTextColor: Color({
				label: 'Hover container text',
				defaultValue: '#121118'
			}),
			bgColor: Color({
				label: 'Container background',
				defaultValue: '#FFFFFF'
			}),
			hoverContainerBgColor: Color({
				label: 'Hover container background',
				defaultValue: '#FFFFFF'
			}),
			hoverContainerBorderColor: Color({
				label: 'Hover container Border',
				defaultValue: '#D1D7E0'
			}),
			dropDownText: TextInput({ label: 'Text container', defaultValue: 'Options' }),
			icon: Image({
				label: 'Container Icon',
				format: Image.Format.WithDimensions
			}),
			shadows: List({
				label: 'Hover Shadows',
				type: Shape({
					type: {
						shadowType: Select({
							label: 'Type',
							labelOrientation: 'horizontal',
							options: [
								{ label: 'Drop shadow', value: 'drop' },
								{ label: 'Inner shadow', value: 'inset' }
							],
							defaultValue: 'drop'
						}),
						color: Color({ label: 'Color', defaultValue: '#000' }),
						distance: Number({ label: 'Distance', defaultValue: 2 }),
						blur: Number({ label: 'Blur', defaultValue: 4 }),
						spread: Number({ label: 'Spread', defaultValue: 0 })
					}
				}),
				getItemLabel() {
					return 'Shadow layer '
				}
			}),
			itemsToDisplay: Number({ label: 'Items to display', step: 1, defaultValue: 3 }),
			textOptionsColor: Color({
				label: 'Text Options',
				defaultValue: '#121118'
			}),
			hoverOptionsTextColor: Color({
				label: 'Hover Options text',
				defaultValue: '#121118'
			}),
			bgMenuColor: Color({
				label: 'Options background',
				defaultValue: '#FFFFFF'
			}),
			hoverOptionsBgColor: Color({
				label: 'Hover Options background',
				defaultValue: '#FFFFFF'
			}),
			hoverOptionsBorderColor: Color({
				label: 'Hover Options Border',
				defaultValue: '#D1D7E0'
			}),
			dropDownMenuStyles: Style({
				properties: [Style.Border, Style.BorderRadius, Style.TextStyle]
			}),
			dropDownOptionsStyles: Style({
				properties: [Style.Width, Style.Padding, Style.Margin]
			}),
			dropDownOptions: List({
				label: 'DropDown options',
				type: Shape({
					type: {
						text: TextInput({ label: 'Text', defaultValue: 'Link' }),
						link: Link({ label: 'On click' }),
						icon: Image({
							label: 'Icon',
							format: Image.Format.WithDimensions
						}),
						hasDivider: Checkbox({ label: 'Divider', defaultValue: false }),
						dividerColor: Color({ label: 'Divider color', defaultValue: '#121118' })
					}
				}),
				getItemLabel(dropDownOption) {
					return dropDownOption?.text || 'Link'
				}
			})
		}
	}
)
