import dynamic from 'next/dynamic'

import { Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./ResourceStats').then(({ ResourceStats }) => ResourceStats)))
	),
	{
		type: 'resource-stats',
		label: 'Contentful/Resource/Resource Stats',
		props: {
			className: Style(),
			disclaimer: TextInput({
				label: 'Disclaimer Text',
				defaultValue: '*Based on a composite organization'
			})
		}
	}
)
