import dynamic from 'next/dynamic'

import {
	Checkbox,
	Color,
	List,
	Number,
	Shape,
	Slot,
	Style,
	TextInput
} from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./PricingCards').then(({ PricingCards }) => PricingCards)))
	),
	{
		type: 'pricing-cards',
		label: 'Pricing Cards',
		props: {
			className: Style(),
			rowGap: Number({
				label: 'Row Gap',
				defaultValue: 4,
				step: 2,
				suffix: 'px',
				selectAll: true
			}),
			columnGap: Number({
				label: 'Column Gap',
				defaultValue: 4,
				step: 2,
				suffix: 'px',
				selectAll: true
			}),
			firstToggleOption: TextInput({ label: 'First Toggle Option', defaultValue: 'Pay Monthly' }),
			secondToggleOption: TextInput({
				label: 'Second Toggle Option',
				defaultValue: 'Pay Annually'
			}),
			active: Checkbox({
				label: 'Active',
				defaultValue: false
			}),
			gridWidth: Style({ properties: [Style.Width] }),
			cards: List({
				label: 'Cards',
				type: Shape({
					type: {
						cardStyle: Style({
							properties: [
								Style.Width,
								Style.Margin,
								Style.Padding,
								Style.Border,
								Style.BorderRadius
							]
						}),
						bgColor: Color({ label: 'Card color', defaultValue: '#FFFFFF' }),
						heading: Slot(),
						monthlyPrice: TextInput({
							label: 'Monthly Price',
							defaultValue: '$0'
						}),
						monthlyPriceStyle: Style({
							properties: [Style.TextStyle]
						}),
						annualPrice: TextInput({
							label: 'Annual Price',
							defaultValue: '$0'
						}),
						annualPriceStyle: Style({
							properties: [Style.TextStyle]
						}),
						showDiscountLabel: Checkbox({
							label: 'Show Discount Label',
							defaultValue: false
						}),
						pillSlot: Slot(),
						monthlyDescription: TextInput({
							label: 'Monthly Description',
							defaultValue: 'per month'
						}),
						annualDescription: TextInput({
							label: 'Annual Description',
							defaultValue: 'per month, billed annually'
						}),
						children: Slot(),
						hasDropDown: Checkbox({
							label: 'Has Dropdown',
							defaultValue: false
						}),
						accordionTextClosed: TextInput({
							label: 'Accordion Text Closed',
							defaultValue: 'See more features'
						}),
						dropDownContent: Slot(),
						accordionTextOpen: TextInput({
							label: 'Accordion Text Open',
							defaultValue: 'See less features'
						})
					}
				}),
				getItemLabel() {
					return 'Card'
				}
			})
		}
	}
)
