import dynamic from 'next/dynamic'

import { Link, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) => dynamic(() => patch(import('./Tag').then(({ Tag }) => Tag)))),
	{
		type: 'tag',
		label: 'Tag',
		props: {
			className: Style({ properties: [Style.Margin] }),
			link: Link(),
			children: TextInput({ defaultValue: 'Tag', selectAll: true })
		}
	}
)
