import dynamic from 'next/dynamic'

import { Combobox, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./BlogPostPDFDownload').then(({ BlogPostPDFDownload }) => BlogPostPDFDownload))
		)
	),
	{
		type: 'blog-post-pdf-download',
		label: 'Contentful/Blog/Blog PDF Download',
		props: {
			className: Style(),
			formId: TextInput({ label: 'Form ID', selectAll: true }),
			thumbnailFieldPath: Combobox({
				label: 'Thumbnail Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'BlogPost',
						filter: (name) => name === 'Asset',
						query
					})
				}
			}),
			buttonText: TextInput({ label: 'Button text', defaultValue: 'Download PDF', selectAll: true })
		}
	}
)
