import { AnchorHTMLAttributes, ReactNode } from 'react'

import NextLink, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

import { DRIFT_REF } from '@/lib/constants'

import { getLocalizedFromEnglish } from '@/utils/locales/localized-routes/getLocalizedFromEnglish'
import { removeTrailingSlash } from '@/utils/locales/localized-routes/helpers/removeTrailingSlash'
import { isFile } from '@/utils/string/fileExtensions'

type Props = LinkProps & {
	href: LinkProps['href']
	children?: ReactNode
	className?: string
	shouldSkipTrailingSlash?: boolean
	dataTestId?: string
	target?: string
	rel?: string
} & AnchorHTMLAttributes<HTMLAnchorElement>

export function Link({
	href,
	children,
	shouldSkipTrailingSlash,
	dataTestId,
	target,
	rel,
	...rest
}: Props) {
	// Checks if link is for internal app pages

	const { locale = 'en-US' } = useRouter()

	// Adds trailing slash if missing
	const hrefWithSlash = (hrefString: string) => {
		let resultString = hrefString
		if (
			!hrefString.startsWith('#') &&
			!hrefString.endsWith('/') &&
			!shouldSkipTrailingSlash &&
			!hrefString.startsWith('https://support.bigcommerce.com/') &&
			!isFile(hrefString) &&
			!hrefString.startsWith('tel:') &&
			!hasQueryParams(hrefString)
		) {
			resultString = `${href.toString()}/`
		}
		return resultString
	}

	const hasQueryParams = (url: string): boolean => {
		try {
			const parsedUrl = new URL(url)
			return parsedUrl.searchParams && parsedUrl.searchParams.toString() !== ''
		} catch (error) {
			return false
		}
	}

	// Localizes the pathname if available
	const localizedPath = getLocalizedFromEnglish(locale as Locale, hrefWithSlash(href.toString()))
	const localizedPathFormat =
		localizedPath.includes(DRIFT_REF) || shouldSkipTrailingSlash
			? removeTrailingSlash(localizedPath)
			: localizedPath

	// If the link is an external link, open in new tab
	const isExternal =
		localizedPathFormat.startsWith('http') && !localizedPathFormat.includes('.bigcommerce.')

	return (
		<NextLink
			data-testid={dataTestId ?? 'custom-next-link'}
			href={localizedPathFormat}
			target={isExternal ? '_blank' : target}
			rel={isExternal ? 'noopener' : rel}
			{...rest}
		>
			{children}
		</NextLink>
	)
}
