import dynamic from 'next/dynamic'

import { Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./GlossarySidebar').then(({ GlossarySidebar }) => GlossarySidebar)))
	),
	{
		type: 'glossary-sidebar',
		label: 'Contentful/Glossary/Glossary Sidebar',
		props: {
			className: Style()
		}
	}
)
