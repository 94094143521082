import dynamic from 'next/dynamic'

import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Breadcrumbs/Breadcrumbs.makeswift'

const { ...rest } = props

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./AppTierBreadcrumbs').then(({ AppTierBreadcrumbs }) => AppTierBreadcrumbs))
		)
	),
	{
		type: 'marketplace-app-tier-breadcrumbs',
		label: 'Contentful/Marketplace/Apps/App Tier Breadcrumbs',
		props: rest
	}
)
