import dynamic from 'next/dynamic'

import { Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./CaseStudyCategoriesList').then(
					({ CaseStudyCategoriesList }) => CaseStudyCategoriesList
				)
			)
		)
	),
	{
		type: 'case-study-categories-list',
		label: 'Contentful/Case Study/Case Study Categories List',
		props: {
			className: Style()
		}
	}
)
