import dynamic from 'next/dynamic'

import { Checkbox, Color, Image, Link, Select, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./Banner').then(({ Banner }) => Banner)))
	),
	{
		type: 'banner',
		label: 'Banner',
		props: {
			className: Style({ properties: Style.All }),
			textColor: Color({
				label: 'Text Color',
				defaultValue: '#FFFFFF'
			}),
			textAlignment: Style({ properties: [Style.Margin] }),
			link: Link({ label: 'Link' }),
			backgroundStyle: Select({
				label: 'Background Color Style',
				options: [
					{ value: 'solid', label: 'Solid' },
					{ value: 'gradient', label: 'Gradient' }
				],
				defaultValue: 'solid'
			}),
			bgColor: Color({
				label: 'Solid Background Color',
				defaultValue: '#000000'
			}),
			gradientColorStop1: Color({
				label: 'Gradient Color Stop 1',
				defaultValue: '#ff80b5'
			}),
			gradientColorStop2: Color({
				label: 'Gradient Color Stop 2',
				defaultValue: '#9089fc'
			}),
			gradientDirection: Select({
				label: 'Gradient Direction',
				options: [
					{ label: 'Top to Bottom', value: 'to bottom' },
					{ label: 'Bottom to Top', value: 'to top' },
					{ label: 'Left to Right', value: 'to right' },
					{ label: 'Right to Left', value: 'to left' },
					{ label: 'Top Left to Bottom Right', value: 'to bottom right' },
					{ label: 'Top Right to Bottom Left', value: 'to bottom left' },
					{ label: 'Bottom Left to Top Right', value: 'to top right' },
					{ label: 'Bottom Right to Top Left', value: 'to top left' }
				],
				defaultValue: 'to bottom'
			}),
			ctaBrand: TextInput({
				label: 'CTA Brand',
				defaultValue: ''
			}),
			ctaDetails: TextInput({
				label: 'CTA Detail',
				defaultValue: ''
			}),
			icon: Image({
				label: 'Icon',
				format: Image.Format.WithDimensions
			}),
			hasCloseIcon: Checkbox({
				label: 'Show Close Icon',
				defaultValue: true
			}),
			closeIconColor: Select({
				label: 'Close Icon Color',
				options: [
					{ value: 'light', label: 'Light' },
					{ value: 'dark', label: 'Dark' }
				],
				defaultValue: 'light'
			})
		}
	}
)
