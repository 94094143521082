import dynamic from 'next/dynamic'

import { TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '../../../common/ContentfulText/ContentfulText.makeswift'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./ArticleChildrenHeading').then(
					({ ArticleChildrenHeading }) => ArticleChildrenHeading
				)
			)
		)
	),
	{
		type: 'article-children-heading',
		label: 'Contentful/Article/Article Children Heading',
		props: {
			...props,
			prefix: TextInput({
				label: 'Prefix',
				defaultValue: 'Featured',
				selectAll: true
			}),
			suffix: TextInput({
				label: 'Suffix',
				defaultValue: 'Resources',
				selectAll: true
			})
		}
	}
)
