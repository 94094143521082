import dynamic from 'next/dynamic'

import { Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./ResourceContent').then(({ ResourceContent }) => ResourceContent)))
	),
	{
		type: 'resource-content',
		label: 'Contentful/Resource/Resource Content',
		props: {
			className: Style()
		}
	}
)
