import dynamic from 'next/dynamic'

import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Breadcrumbs/Breadcrumbs.makeswift'

const { ...rest } = props

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./CategoryBreadcrumbs').then(({ CategoryBreadcrumbs }) => CategoryBreadcrumbs))
		)
	),
	{
		type: 'category-breadcrumbs',
		label: 'Contentful/Category/Category Breadcrumbs',
		props: rest
	}
)
