import dynamic from 'next/dynamic'

import { Color, List, Select, Shape, Slot, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { FolderStructure } from '@/utils/makeswift/constants'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./PillTabs').then(({ PillTabs }) => PillTabs)))
	),
	{
		type: 'PillTabs',
		label: `${FolderStructure.UnderDevelopment}/Pill Tabs`,
		props: {
			className: Style(),
			pills: List({
				label: 'Pill',
				type: Shape({
					type: {
						title: TextInput({
							label: 'Pill title',
							defaultValue: 'Pill title',
							selectAll: true
						}),
						content: Slot()
					}
				}),
				getItemLabel(pill) {
					return pill?.title || 'Pill title'
				}
			}),
			containerAlignment: Select({
				label: 'Container alignment',
				options: [
					{ value: 'left', label: 'Left' },
					{ value: 'center', label: 'Center' },
					{ value: 'right', label: 'Right' }
				],
				defaultValue: 'left'
			}),
			activeTextColor: Color({
				label: 'Active Text',
				defaultValue: '#000000'
			}),
			activeBgColor: Color({
				label: 'Active Background',
				defaultValue: '#ffffff'
			}),
			activeBorderColor: Color({
				label: 'Active Border',
				defaultValue: '#000000'
			}),
			inactiveTextColor: Color({
				label: 'Inactive Text',
				defaultValue: '#808080'
			}),
			inactiveBgColor: Color({
				label: 'Inactive Background',
				defaultValue: '#ffffff'
			}),
			inactiveBorderColor: Color({
				label: 'Inactive Border',
				defaultValue: '#808080'
			}),
			hoverTextColor: Color({
				label: 'Hover Text',
				defaultValue: '#000000'
			}),
			hoverBgColor: Color({
				label: 'Hover Background',
				defaultValue: '#000000'
			}),
			hoverBorderColor: Color({
				label: 'Hover Border',
				defaultValue: '#000000'
			})
		}
	}
)
