import dynamic from 'next/dynamic'

import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Breadcrumbs/Breadcrumbs.makeswift'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./PressReleasesBreadcrumbs').then(
					({ PressReleasesBreadcrumbs }) => PressReleasesBreadcrumbs
				)
			)
		)
	),
	{
		type: 'press-releases-breadcrumbs',
		label: 'Contentful/Press/Press Releases Breadcrumbs',
		props
	}
)
