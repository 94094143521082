import { routesMap } from '../routesMap'

export function isSupportedLocale(locale: string | undefined): locale is Locale {
	if (!locale) return false

	if (
		routesMap.has(locale as Locale) ||
		locale === 'en-US' ||
		locale === 'en-GB' ||
		locale === 'en-AU' ||
		locale === 'en-SG' ||
		locale === 'x-default'
	) {
		return true
	}

	return false
}
