import dynamic from 'next/dynamic'

import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '../AppRow/AppRow.makeswift'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./AppRowCards').then(({ AppRowCards }) => AppRowCards)))
	),
	{
		type: 'marketplace-app-row-cards',
		label: 'Contentful/Marketplace/Apps/App Row Cards',
		props
	}
)
