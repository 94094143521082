import dynamic from 'next/dynamic'

import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./AppCategoryList').then(({ AppCategoryList }) => AppCategoryList)))
	),
	{
		type: 'marketplace-app-category-list',
		label: 'Contentful/Marketplace/Apps/App Category List'
	}
)
