import dynamic from 'next/dynamic'

import { Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./ResourceRecommendedFeed').then(
					({ ResourceRecommendedFeed }) => ResourceRecommendedFeed
				)
			)
		)
	),
	{
		type: 'resource-recommended-feed',
		label: 'Contentful/Resource/Resource Recommended Feed',
		props: {
			className: Style(),
			ctaText: TextInput({
				label: 'CTA Text',
				defaultValue: 'Learn More',
				selectAll: true
			}),
			title: TextInput({
				label: 'Section Title',
				defaultValue: 'Recommended for you',
				selectAll: true
			})
		}
	}
)
