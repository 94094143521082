import { getLocalizedFromEnglish } from './getLocalizedFromEnglish'
import { addTrailingSlash } from './helpers/addTrailingSlash'
import { isSupportedLocale } from './helpers/isSupportedLocale'

export default function getLocalizedPathFromEnglish(locale: string | undefined, route: string) {
	const normalizedPath = route.replace(/^\/|\/$/g, '')
	if (!isSupportedLocale(locale)) {
		return addTrailingSlash(normalizedPath)
	}

	return getLocalizedFromEnglish(locale, normalizedPath)
}
