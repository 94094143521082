import dynamic from 'next/dynamic'

import { MakeswiftComponentType } from '@makeswift/runtime/components'
import { RichText, Select } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'
import { Props } from '@makeswift/runtime/prop-controllers'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) => dynamic(() => patch(import('./Text').then(({ Text }) => Text)))),
	{
		type: MakeswiftComponentType.Text,
		label: 'Text',
		props: {
			id: Props.ElementID(),
			text: RichText(),
			width: Props.Width({
				format: Props.Width.Format.ClassName,
				preset: [{ deviceId: 'desktop', value: { value: 700, unit: 'px' } }],
				defaultValue: { value: 100, unit: '%' }
			}),
			margin: Props.Margin({
				format: Props.Margin.Format.ClassName,
				preset: [
					{
						deviceId: 'desktop',
						value: {
							marginTop: null,
							marginRight: 'auto',
							marginBottom: { value: 20, unit: 'px' },
							marginLeft: 'auto'
						}
					}
				]
			}),
			linkColor: Select({
				label: 'Link color',
				options: [
					{ value: 'blue', label: 'Blue' },
					{ value: 'green', label: 'Green' }
				],
				defaultValue: 'blue'
			}),
			listMarkerColor: Select({
				label: 'List marker color',
				options: [
					{ value: 'blue', label: 'Blue' },
					{ value: 'black', label: 'Black' },
					{ value: 'white', label: 'White' }
				],
				defaultValue: 'blue'
			})
		}
	}
)
