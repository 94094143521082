import dynamic from 'next/dynamic'

import { Image, Link, List, Number, Shape, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./Footer').then(({ Footer }) => Footer)))
	),
	{
		type: 'footer',
		label: 'Footer',
		props: {
			className: Style(),
			footerGroups: List({
				label: 'Footer groups',
				type: Shape({
					type: {
						heading: TextInput({
							label: 'Group heading',
							defaultValue: 'Heading',
							selectAll: true
						}),
						footerLinks: List({
							label: 'Links',
							type: Shape({
								type: {
									text: TextInput({ label: 'Text', defaultValue: 'Link', selectAll: true }),
									link: Link({ label: 'On click' })
								}
							}),
							getItemLabel(link) {
								return link?.text || 'Link'
							}
						})
					}
				}),
				getItemLabel(group) {
					return group?.heading || 'Heading'
				}
			}),
			logoImage: Image({
				label: 'Logo',
				format: Image.Format.WithDimensions
			}),
			logoWidth: Number({ label: 'Logo width', defaultValue: 110, suffix: 'px', selectAll: true }),
			logoAlt: TextInput({ label: 'Logo alt text', defaultValue: 'logo', selectAll: true }),
			logoLink: Link({ label: 'Logo on click' }),
			socialLinks: List({
				label: 'Social links',
				type: Shape({
					type: {
						text: TextInput({ label: 'Text', defaultValue: 'Link', selectAll: true }),
						link: Link({ label: 'On click' })
					}
				}),
				getItemLabel(socialLink) {
					return socialLink?.text || 'Link'
				}
			}),
			copyrightLinkText: TextInput({
				label: 'Copyright link text',
				defaultValue: 'Privacy Policy',
				selectAll: true
			}),
			copyrightLink: Link({ label: 'Copyright link' }),
			cookieSettingsLinkText: TextInput({
				label: 'Cookie settings link text',
				defaultValue: 'Cookie Settings',
				selectAll: true
			})
		}
	}
)
