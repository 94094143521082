import { Color, Style, TextInput } from '@makeswift/runtime/controls'

export const props = {
	className: Style({
		properties: [Style.Margin, Style.Width, Style.BorderRadius, Style.Border]
	}),
	heading: TextInput({ label: 'Heading', defaultValue: 'Share this article' }),
	baseUrl: TextInput({ label: 'Base Url' }),
	color: Color()
}
