import dynamic from 'next/dynamic'

import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/CardCarousel/CardCarousel.makeswift'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./AppsRecommendedCarousel').then(
					({ AppsRecommendedCarousel }) => AppsRecommendedCarousel
				)
			)
		)
	),
	{
		type: 'marketplace-app-recommended-carousel',
		label: 'Contentful/Marketplace/Apps/Apps Recommended Carousel',
		props
	}
)
