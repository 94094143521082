import dynamic from 'next/dynamic'

import { Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

const props = {
	className: Style(),
	title: TextInput({ label: 'Section Title', defaultValue: 'App Features', selectAll: true })
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./AppFeatures').then(({ AppFeatures }) => AppFeatures)))
	),
	{
		type: 'marketplace-app-features',
		label: 'Contentful/Marketplace/Apps/App Features',
		props
	}
)
