import dynamic from 'next/dynamic'

import { Select, Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	type: Select({
		label: 'Type',
		options: [
			{ label: 'Featured', value: 'featured' },
			{ label: 'Recommended', value: 'recommended' },
			{ label: 'Top Free', value: 'top-free' },
			{ label: 'Top Paid', value: 'top-paid' },
			{ label: 'Newest', value: 'newest' }
		],
		defaultValue: 'featured'
	})
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./AppRow').then(({ AppRow }) => AppRow)))
	),
	{
		type: 'marketplace-app-row',
		label: 'Contentful/Marketplace/Apps/App Row',
		props
	}
)
