import dynamic from 'next/dynamic'

import { Color, Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./CaseStudyStats').then(({ CaseStudyStats }) => CaseStudyStats)))
	),
	{
		type: 'case-study-stats',
		label: 'Contentful/Case Study/Case Study Stats',
		props: {
			className: Style(),
			textColor: Color({
				label: 'Text Color',
				defaultValue: '#121118'
			})
		}
	}
)
