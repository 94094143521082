import dynamic from 'next/dynamic'

import { Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./PersonRelatedNews').then(({ PersonRelatedNews }) => PersonRelatedNews))
		)
	),
	{
		type: 'person-related-news',
		label: 'Contentful/Person/Person Related News',
		props: {
			className: Style()
		}
	}
)
