import dynamic from 'next/dynamic'

import { Combobox } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulRichText/ContentfulRichText.makeswift'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./BlogPostRichText').then(({ BlogPostRichText }) => BlogPostRichText))
		)
	),
	{
		type: 'blog-post-rich-text',
		label: 'Contentful/Blog/Blog Rich Text',
		props: {
			...props,
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'BlogPost',
						filter: (name) => name === 'RichText',
						query
					})
				}
			})
		}
	}
)
