import dynamic from 'next/dynamic'

import {
	Checkbox,
	Image,
	Link,
	List,
	Number,
	Shape,
	Style,
	TextInput
} from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./Navigation').then(({ Navigation }) => Navigation)))
	),
	{
		type: 'navigation',
		label: 'Navigation',
		props: {
			className: Style({ properties: [Style.Width, Style.Margin] }),
			navigationTabs: List({
				label: 'Navigation tabs',
				type: Shape({
					type: {
						text: TextInput({ label: 'Text', defaultValue: 'Link', selectAll: true }),
						link: Link({ label: 'On click' }),
						active: Checkbox({ label: 'Active' })
					}
				}),
				getItemLabel(topNavLink) {
					return topNavLink?.text || 'Link'
				}
			}),
			countries: List({
				label: 'Locales',
				type: Shape({
					type: {
						flag: TextInput({ label: 'Flag', defaultValue: '🇺🇸', selectAll: true }),
						name: TextInput({ label: 'Name', defaultValue: 'Country', selectAll: true }),
						locale: TextInput({ label: 'Locale', defaultValue: 'com', selectAll: true })
					}
				}),
				getItemLabel(country) {
					return country?.name || 'Country'
				}
			}),
			topNavLinks: List({
				label: 'Top navigation',
				type: Shape({
					type: {
						text: TextInput({ label: 'Text', defaultValue: 'Link', selectAll: true }),
						link: Link({ label: 'On click' })
					}
				}),
				getItemLabel(topNavLink) {
					return topNavLink?.text || 'Link'
				}
			}),
			logoImage: Image({
				label: 'Desktop logo',
				format: Image.Format.WithDimensions
			}),
			logoWidth: Number({
				label: 'Desktop logo width',
				defaultValue: 132,
				suffix: 'px',
				selectAll: true
			}),
			mobileLogoImage: Image({
				label: 'Mobile logo',
				format: Image.Format.WithDimensions
			}),
			mobileLogoWidth: Number({
				label: 'Mobile logo width',
				defaultValue: 32,
				suffix: 'px',
				selectAll: true
			}),
			logoAlt: TextInput({
				label: 'Logo alt text',
				defaultValue: 'BigCommerce logo',
				selectAll: true
			}),
			logoLink: Link({ label: 'Logo on click' }),
			mainNavLinks: List({
				label: 'Main navigation',
				type: Shape({
					type: {
						text: TextInput({ label: 'Text', defaultValue: 'Link', selectAll: true }),
						link: Link({ label: 'On click' }),
						subnavGroups: List({
							label: 'Subnav groups',
							type: Shape({
								type: {
									heading: TextInput({
										label: 'Heading',
										defaultValue: 'Heading',
										selectAll: true
									}),
									subnavLinks: List({
										label: 'Links',
										type: Shape({
											type: {
												text: TextInput({ label: 'Text', defaultValue: 'Link', selectAll: true }),
												subtext: TextInput({ label: 'Subtext', defaultValue: '', selectAll: true }),
												link: Link({ label: 'On click' })
											}
										}),
										getItemLabel(subnavLink) {
											return subnavLink?.text || 'Link'
										}
									})
								}
							}),
							getItemLabel(subnavGroup) {
								return subnavGroup?.heading || 'Heading'
							}
						})
					}
				}),
				getItemLabel(mainNavLink) {
					return mainNavLink?.text || 'Link'
				}
			}),
			ctaText: TextInput({ label: 'CTA text', defaultValue: 'Request a demo', selectAll: true }),
			ctaLink: Link({ label: 'CTA on click' })
		}
	}
)
