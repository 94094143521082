import dynamic from 'next/dynamic'

import { Combobox } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/MarketoForm/MarketoForm.makeswift'

import { getFieldOptions } from '../../../../../lib/contentful/utils'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./ResourceForm').then(({ ResourceForm }) => ResourceForm)))
	),
	{
		type: 'resource-form',
		label: 'Contentful/Resource/Resource Form',
		props: {
			...props,
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'Resource',
						filter: (name) => name === 'String',
						query
					})
				}
			})
		}
	}
)
