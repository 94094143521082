import dynamic from 'next/dynamic'

import { Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./CaseStudyRelatedCaseStudies').then(
					({ CaseStudyRelatedCaseStudies }) => CaseStudyRelatedCaseStudies
				)
			)
		)
	),
	{
		type: 'case-study-related-case-studies',
		label: 'Contentful/Case Study/Case Study Related Case Studies',
		props: {
			className: Style(),
			cardCtaText: TextInput({
				label: 'Case Study CTA Text',
				defaultValue: 'Read Their Story'
			})
		}
	}
)
