import dynamic from 'next/dynamic'

import { Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

const props = {
	className: Style(),
	ctaText: TextInput({ label: 'CTA Text', defaultValue: 'Read their story', selectAll: true }),
	title: TextInput({ label: 'Section Title', defaultValue: 'Case Studies', selectAll: true })
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./AppCaseStudies').then(({ AppCaseStudies }) => AppCaseStudies)))
	),
	{
		type: 'marketplace-app-case-studies',
		label: 'Contentful/Marketplace/Apps/App Case Studies',
		props
	}
)
