type Args = {
	searchValue: string
	map?: Map<string, string>
}

export function getByMapValue({ map, searchValue }: Args) {
	if (!map) {
		return searchValue
	}

	for (let [key, value] of map.entries()) {
		if (value === searchValue) return key
	}
}
