import dynamic from 'next/dynamic'

import { Checkbox, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./CaseStudyFilters').then(({ CaseStudyFilters }) => CaseStudyFilters))
		)
	),
	{
		type: 'case-study-filters',
		label: 'Contentful/Case Study/Case Study Filters',
		props: {
			className: Style(),
			filterButtonLabel: TextInput({
				label: 'Filter Button Label',
				defaultValue: 'Filter'
			}),
			industryColumnHeader: TextInput({
				label: 'Industry Column Header',
				defaultValue: 'By Industry'
			}),
			topicColumnHeader: TextInput({
				label: 'Topic Column Header',
				defaultValue: 'By Topic'
			}),
			competitorColumnHeader: TextInput({
				label: 'Competitor Column Header',
				defaultValue: 'By Migration Story'
			}),
			cancelButtonLabel: TextInput({
				label: 'Cancel Button Label',
				defaultValue: 'Cancel'
			}),
			clearButtonLabel: TextInput({
				label: 'Clear Button Label',
				defaultValue: 'Clear All'
			}),
			applyButtonLabel: TextInput({
				label: 'Apply Button Label',
				defaultValue: 'Apply'
			}),
			hideInDesktop: Checkbox({ label: 'Hide in Desktop', defaultValue: false }),
			resultsLabel: TextInput({
				label: 'Apply Results Label',
				defaultValue: 'Results'
			})
		}
	}
)
