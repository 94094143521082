import { capitalize } from '@/utils/string/capitalize'

import { getByMapValue } from './helpers/getByMapValue'
import { isSupportedLocale } from './helpers/isSupportedLocale'
import { routesMap } from './routesMap'

type Args = {
	locale: string
	name: string
	capitalized?: boolean
}

export default function getLocalizedNameFromEnglish({ locale, name, capitalized }: Args) {
	if (!isSupportedLocale(locale)) return name

	const translated = getByMapValue({ map: routesMap.get(locale), searchValue: name }) ?? name
	return capitalized ? capitalize(translated) : translated
}
