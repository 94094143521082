import dynamic from 'next/dynamic'

import { Select, Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./ArticleSecondaryCTAs').then(({ ArticleSecondaryCTAs }) => ArticleSecondaryCTAs)
			)
		)
	),
	{
		type: 'article-secondary-ctas',
		label: 'Contentful/Article/Secondary CTAs',
		props: {
			className: Style(),
			buttonColor: Select({
				label: 'Color',
				options: [
					{ value: 'primary', label: 'Blue' },
					{ value: 'secondary', label: 'Black' },
					{ value: 'tertiary', label: 'White' }
				],
				defaultValue: 'secondary'
			})
		}
	}
)
