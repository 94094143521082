import dynamic from 'next/dynamic'

import { Checkbox, Slot } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./NavigationGridContainer').then(
					({ NavigationGridContainer }) => NavigationGridContainer
				)
			)
		)
	),
	{
		type: 'navigation-grid-container',
		label: 'Primary Navigation Grid Container',
		props: {
			mainContent: Slot(),
			secondaryContent: Slot(),
			hasFeaturedSection: Checkbox({ label: 'Enable Featured Section', defaultValue: false }),
			hasFeaturedBackgroundColor: Checkbox({ label: 'Featured Background', defaultValue: false })
		}
	}
)
