import dynamic from 'next/dynamic'

import { Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./ArticleChildFeedGrid').then(({ ArticleChildFeedGrid }) => ArticleChildFeedGrid)
			)
		)
	),
	{
		type: 'article-child-feed-grid',
		label: 'Contentful/Article/Article Child Feed Grid',
		props: {
			className: Style()
		}
	}
)
