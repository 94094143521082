import dynamic from 'next/dynamic'

import { Number, Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./AppTierAppGrid').then(({ AppTierAppGrid }) => AppTierAppGrid)))
	),
	{
		type: 'marketplace-app-tier-app-grid',
		label: 'Contentful/Marketplace/Apps/App Tier App Grid',
		props: {
			className: Style(),
			appCount: Number({
				label: 'App Count',
				defaultValue: 12
			})
		}
	}
)
