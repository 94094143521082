import dynamic from 'next/dynamic'

import { Number } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props as CardCarouselProps } from '@/components/CardCarousel/CardCarousel.makeswift'

import { props } from '../AppRow/AppRow.makeswift'

const { ...rest } = CardCarouselProps

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./AppCarousel').then(({ AppsCarousel }) => AppsCarousel)))
	),
	{
		type: 'marketplace-app-carousel',
		label: 'Contentful/Marketplace/Apps/App Carousel',
		props: {
			...props,
			limit: Number({
				label: 'Max Number of Apps Shown',
				defaultValue: 16,
				min: 1,
				max: 48,
				step: 1,
				unit: 'apps'
			}),
			...rest
		}
	}
)
