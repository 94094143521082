import { Asset, AssetExternal, AssetInternal } from '@/generated/contentful'

export function normalizeImage(entry?: any) {
	const isAssetExternal = (entry?: any): entry is AssetExternal =>
		entry?.__typename === 'AssetExternal'
	const isAssetInternal = (entry?: any): entry is AssetInternal =>
		entry?.__typename === 'AssetInternal'
	const isAsset = (entry?: any): entry is Asset => entry?.__typename === 'Asset'

	if (isAsset(entry)) {
		return {
			url: entry?.url ?? '',
			alt: entry?.title,
			dimensions: {
				width: entry?.width ?? 0,
				height: entry?.height ?? 0
			}
		}
	}

	if (isAssetExternal(entry)) {
		return {
			url: entry?.url ?? '',
			alt: entry?.altText,
			dimensions: {}
		}
	}

	if (isAssetInternal(entry)) {
		return {
			url: entry?.media?.url ?? '',
			alt: entry?.media?.title,
			dimensions: {
				width: entry?.media?.width ?? 0,
				height: entry?.media?.height ?? 0
			}
		}
	}
}
