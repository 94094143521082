import dynamic from 'next/dynamic'

import { Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./BlogPostCategories').then(({ BlogPostCategories }) => BlogPostCategories))
		)
	),
	{
		type: 'blog-post-categories',
		label: 'Contentful/Blog/Blog Categories',
		props: {
			className: Style()
		}
	}
)
