import dynamic from 'next/dynamic'

import { Link, List, Select, Shape, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	breadcrumbs: List({
		label: 'Links',
		type: Shape({
			type: {
				text: TextInput({ label: 'Text', defaultValue: 'Page name', selectAll: true }),
				link: Link({ label: 'On click' }),
				schemaUrl: TextInput({
					label: 'Schema URL',
					selectAll: true
				})
			}
		}),
		getItemLabel(breadcrumb) {
			return breadcrumb?.text || 'Page name'
		}
	}),
	currentPage: TextInput({
		label: 'Current page text',
		defaultValue: 'Current page',
		selectAll: true
	}),
	variant: Select({
		label: 'Appearance',
		options: [
			{ value: 'light', label: 'Light mode' },
			{ value: 'dark', label: 'Dark mode' }
		],
		defaultValue: 'light'
	})
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./Breadcrumbs').then(({ Breadcrumbs }) => Breadcrumbs)))
	),
	{
		type: 'breadcrumbs',
		label: 'Breadcrumbs',
		props
	}
)
