import dynamic from 'next/dynamic'

import { Combobox, List } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulBox/ContentfulBox.makeswift'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./ResourceBox').then(({ ResourceBox }) => ResourceBox)))
	),
	{
		type: 'resource-box',
		label: 'Contentful/Resource/Resource Box',
		props: {
			...props,
			requiredFieldPaths: List({
				label: 'Required fields',
				type: Combobox({
					label: 'Field',
					async getOptions(query: string) {
						return getFieldOptions({
							type: 'Resource',
							query
						})
					}
				}),
				getItemLabel(item) {
					return item?.label ?? 'Untitled'
				}
			})
		}
	}
)
