import dynamic from 'next/dynamic'

import { Select, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/CardCarousel/CardCarousel.makeswift'

const { ...rest } = props

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./ArticleFeedCarousel').then(({ ArticleFeedCarousel }) => ArticleFeedCarousel))
		)
	),
	{
		type: 'article-carousel',
		label: 'Contentful/Article/Article Carousel',
		props: {
			...rest,
			type: Select({
				label: 'Type',
				options: [
					{ label: 'Root', value: 'root' },
					{ label: 'Popular', value: 'popular' },
					{ label: 'Latest', value: 'latest' }
				],
				defaultValue: 'root'
			}),
			ctaText: TextInput({
				label: 'CTA Text',
				defaultValue: 'Read Article',
				selectAll: true
			})
		}
	}
)
