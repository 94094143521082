import dynamic from 'next/dynamic'

import { Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./CaseStudySideBar').then(({ CaseStudySideBar }) => CaseStudySideBar))
		)
	),
	{
		type: 'case-study-sidebar',
		label: 'Contentful/Case Study/Case Study Side Bar',
		props: {
			className: Style(),
			filterButtonLabel: TextInput({
				label: 'Filter Button Label',
				defaultValue: 'Filters'
			}),
			industryColumnHeader: TextInput({
				label: 'Industry Column Header',
				defaultValue: 'By Industry'
			}),
			topicColumnHeader: TextInput({
				label: 'Topic Column Header',
				defaultValue: 'By Topic'
			}),
			competitorColumnHeader: TextInput({
				label: 'Competitor Column Header',
				defaultValue: 'By Migration Story'
			}),
			applyButtonLabel: TextInput({
				label: 'Apply Button Label',
				defaultValue: 'Apply'
			})
		}
	}
)
