import { ButtonContentProps } from '../Button'

const validVariants: ButtonContentProps['variant'][] = ['filled', 'outlined', 'subtle']
const validColors: ButtonContentProps['color'][] = [
	'primary',
	'secondary',
	'tertiary',
	'quaternary'
]

export const getValidColor = (color: ButtonContentProps['color']) => {
	if (!color) return 'primary'

	if (!validColors.includes(color)) {
		switch (color?.toLowerCase()) {
			case 'black':
				return 'secondary'
			case 'blue':
				return 'primary'
			default:
				return 'tertiary'
		}
	}

	return color
}

export const getValidVariant = (variant: ButtonContentProps['variant']) => {
	if (!variant) return 'filled'

	if (!validVariants.includes(variant)) {
		switch (variant?.toLowerCase()) {
			case 'secondary':
				return 'subtle'
			case 'tertiary':
				return 'outlined'
			default:
				return 'filled'
		}
	}

	return variant
}
