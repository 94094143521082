import dynamic from 'next/dynamic'

import { Combobox, List, Style, TextInput } from '@makeswift/runtime/controls'
import { Descriptor } from '@makeswift/runtime/dist/types/prop-controllers/descriptors'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'
import Fuse from 'fuse.js'

import { ShowcaseFeedDocument, ShowcaseOrder } from '@/generated/contentful'

import { client } from '@/lib/contentful/client'
import { runtime } from '@/lib/makeswift/runtime'

type FnArgs = {
	label: string
	category: string
}

export type ShowcasePriorityCategories = {
	showcase?: string[]
	automotive?: string[]
	b2b?: string[]
	'education-publishing'?: string[]
	electronics?: string[]
	'fashion-apparel'?: string[]
	'food-beverage'?: string[]
	beverage?: string[]
	other?: string[]
	'specialty-food'?: string[]
	wine?: string[]
	headless?: string[]
	'health-beauty'?: string[]
	'home-garden'?: string[]
	luxury?: string[]
	'multi-storefront'?: string[]
	'sports-outdoors'?: string[]
	baseball?: string[]
	cycling?: string[]
	golf?: string[]
	outdoors?: string[]
	'pro-sports'?: string[]
	'toys-games'?: string[]
}

const getCategoryList = ({ label, category }: FnArgs) =>
	List({
		label,
		type: Combobox({
			label: 'Showcase Item',
			async getOptions(query?: string) {
				const { showcaseCollection } = await client().request(ShowcaseFeedDocument, {
					locale: 'en-US',
					where: { status: true, category: { slug: category } },
					order: [ShowcaseOrder.TitleAsc],
					limit: 200
				})

				const fuse = new Fuse(showcaseCollection?.items ?? [], {
					keys: ['title', 'slug_en_US', 'slug'],
					threshold: 0.4
				})

				return query?.length
					? fuse.search(query ?? '').map(({ item }) => ({
							id: item?.sys.id ?? '',
							label: item?.title ?? '',
							value: item?.sys.id ?? ''
					  }))
					: showcaseCollection?.items?.map((item) => ({
							id: item?.sys.id ?? '',
							label: item?.title ?? '',
							value: item?.sys.id ?? ''
					  })) ?? []
			}
		}),
		getItemLabel(item) {
			return item?.label ?? 'No name'
		}
	})

export const props = {
	className: Style(),
	nextText: TextInput({
		label: 'Next Page Text',
		defaultValue: 'Next'
	}),
	prevText: TextInput({
		label: 'Previous Page Text',
		defaultValue: 'Previous'
	}),
	showcase: getCategoryList({ label: 'Priority: Featured', category: 'showcase' }),
	automotive: getCategoryList({ label: 'Priority: Automotive', category: 'automotive' }),
	b2b: getCategoryList({ label: 'Priority: B2B', category: 'b2b' }),
	'education-publishing': getCategoryList({
		label: 'Priority: Education & Publishing',
		category: 'education-publishing'
	}),
	electronics: getCategoryList({ label: 'Priority: Electronics', category: 'electronics' }),
	'fashion-apparel': getCategoryList({
		label: 'Priority: Fashion & Apparel',
		category: 'fashion-apparel'
	}),
	'food-beverage': getCategoryList({
		label: 'Priority: Food & Beverage',
		category: 'food-beverage'
	}),
	beverage: getCategoryList({ label: 'Priority: Beverage', category: 'beverage' }),
	other: getCategoryList({ label: 'Priority: Other', category: 'other' }),
	'specialty-food': getCategoryList({
		label: 'Priority: Specialty Food',
		category: 'specialty-food'
	}),
	wine: getCategoryList({ label: 'Priority: Wine', category: 'wine' }),
	headless: getCategoryList({ label: 'Priority: Headless', category: 'headless' }),
	'health-beauty': getCategoryList({
		label: 'Priority: Health & Beauty',
		category: 'health-beauty'
	}),
	'home-garden': getCategoryList({ label: 'Priority: Home & Garden', category: 'home-garden' }),
	luxury: getCategoryList({ label: 'Priority: Luxury', category: 'luxury' }),
	'multi-storefront': getCategoryList({
		label: 'Priority: Multi-Storefront',
		category: 'multi-storefront'
	}),
	'sports-outdoors': getCategoryList({
		label: 'Priority: Sports & Outdoors',
		category: 'sports-outdoors'
	}),
	baseball: getCategoryList({ label: 'Priority: Baseball', category: 'baseball' }),
	cycling: getCategoryList({ label: 'Priority: Cycling', category: 'cycling' }),
	golf: getCategoryList({ label: 'Priority: Golf', category: 'golf' }),
	outdoors: getCategoryList({ label: 'Priority: Outdoors', category: 'outdoors' }),
	'pro-sports': getCategoryList({ label: 'Priority: Pro Sports', category: 'pro-sports' }),
	'toys-games': getCategoryList({ label: 'Priority: Toys & Games', category: 'toys-games' }),
	caseStudy: TextInput({
		label: 'Case Study',
		defaultValue: 'Case Study'
	}),
	partnerCaseStudy: TextInput({
		label: 'Partner Case Study',
		defaultValue: 'Partner Case Study'
	})
} as unknown as Record<string, Descriptor>

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./ShowcaseFeed').then(({ ShowcaseFeed }) => ShowcaseFeed)))
	),
	{
		type: 'showcase-feed',
		label: 'Contentful/Showcase/Showcase Feed',
		props
	}
)
