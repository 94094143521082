"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.client = void 0;
var graphql_request_1 = require("graphql-request");
var client = function (_a) {
    var _b = _a === void 0 ? {} : _a, preview = _b.preview;
    return new graphql_request_1.GraphQLClient("https://graphql.contentful.com/content/v1/spaces/".concat(process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID, "/environments/").concat(process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT), {
        headers: {
            Authorization: "Bearer ".concat(Boolean(preview)
                ? process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN
                : process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN)
        }
    });
};
exports.client = client;
