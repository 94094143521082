import dynamic from 'next/dynamic'

import { Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./GlossaryFeed').then(({ GlossaryFeed }) => GlossaryFeed)))
	),
	{
		type: 'glossary-feed',
		label: 'Contentful/Glossary/Glossary Feed',
		props: {
			className: Style(),
			ctaText: TextInput({
				label: 'Glossary CTA Text',
				defaultValue: 'Read story'
			})
		}
	}
)
