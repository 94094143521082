import dynamic from 'next/dynamic'

import { TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Breadcrumbs/Breadcrumbs.makeswift'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./CaseStudyBreadcrumbs').then(({ CaseStudyBreadcrumbs }) => CaseStudyBreadcrumbs)
			)
		)
	),
	{
		type: 'case-study-breadcrumbs',
		label: 'Contentful/Case Study/Case Study Breadcrumbs',
		props: {
			...props,
			caseStudyHomepageLabel: TextInput({
				label: 'Case Study Homepage Label',
				defaultValue: 'Case Studies',
				selectAll: true
			})
		}
	}
)
