export const SKU_OPTIONS = [
	{
		id: 'STORE-TRIAL-15DAY',
		label: '15 Day Trial (sku:STORE-TRIAL-15DAY)',
		value: 'STORE-TRIAL-15DAY'
	},
	{
		id: 'STORE-TRIAL-15DAY-CAPITALONE',
		label: '15 Day Capital One Trial (sku:STORE-TRIAL-15DAY-CAPITALONE)',
		value: 'STORE-TRIAL-15DAY-CAPITALONE'
	},
	{
		id: 'STORE-TRIAL-15DAY-SQUARE',
		label: '15 Day Square Trial (sku:STORE-TRIAL-15DAY-SQUARE)',
		value: 'STORE-TRIAL-15DAY-SQUARE'
	},
	{
		id: 'STORE-TRIAL-15DAY-PROMO-1MO',
		label: '15 Day Trial - 1 mo Free (sku:STORE-TRIAL-15DAY-PROMO-1MO)',
		value: 'STORE-TRIAL-15DAY-PROMO-1MO'
	},
	{
		id: 'STORE-TRIAL-15DAY-PROMO-1MO-CONTROL',
		label: '15 Day Trial - 1 mo Free - Control Group (sku:STORE-TRIAL-15DAY-PROMO-1MO-CONTROL)',
		value: 'STORE-TRIAL-15DAY-PROMO-1MO-CONTROL'
	},
	{
		id: 'STORE-TRIAL-15DAY-PROMO-1MO-TEST',
		label: '15 Day Trial - 1 mo Free - Test Group (sku:STORE-TRIAL-15DAY-PROMO-1MO-TEST)',
		value: 'STORE-TRIAL-15DAY-PROMO-1MO-TEST'
	},
	{
		id: 'STORE-TRIAL-15DAY-PROMO-2MO',
		label: '15 Day Trial - 2 mo Free (sku:STORE-TRIAL-15DAY-PROMO-2MO)',
		value: 'STORE-TRIAL-15DAY-PROMO-2MO'
	},
	{
		id: 'STORE-TRIAL-15DAY-PROMO-2MOANNUAL',
		label: '15 Day Trial - 2 mo Free Annual (sku:STORE-TRIAL-15DAY-PROMO-2MOANNUAL)',
		value: 'STORE-TRIAL-15DAY-PROMO-2MOANNUAL'
	},
	{
		id: 'STORE-TRIAL-15DAY-PROMO-25OFF',
		label: '15 Day Trial - 25% Off (sku:STORE-TRIAL-15DAY-PROMO-25OFF)',
		value: 'STORE-TRIAL-15DAY-PROMO-25OFF'
	},
	{
		id: 'STORE-TRIAL-15DAY-PROMO-3MO',
		label: '15 Day Trial - 3 mo Free (sku:STORE-TRIAL-15DAY-PROMO-3MO)',
		value: 'STORE-TRIAL-15DAY-PROMO-3MO'
	},
	{
		id: 'STORE-TRIAL-15DAY-PROMO-4MO',
		label: '15 Day Trial - 4 mo Free (sku:STORE-TRIAL-15DAY-PROMO-4MO)',
		value: 'STORE-TRIAL-15DAY-PROMO-4MO'
	},
	{
		id: 'STORE-TRIAL-15DAY-PROMO-50OFF',
		label: '15 Day Trial - 50% Off (sku:STORE-TRIAL-15DAY-PROMO-50OFF)',
		value: 'STORE-TRIAL-15DAY-PROMO-50OFF'
	},
	{
		id: 'STORE-TRIAL-15DAY-PROMO-6MO',
		label: '15 Day Trial - 6 mo Free (sku:STORE-TRIAL-15DAY-PROMO-6MO)',
		value: 'STORE-TRIAL-15DAY-PROMO-6MO'
	},
	{
		id: 'STORE-TRIAL-15DAY-PROMO-STD0PLUS1PRO2',
		label: '15 Day Trial - Standard 0 Plus 1 Pro 2 (sku:STORE-TRIAL-15DAY-PROMO-STD0PLUS1PRO2)',
		value: 'STORE-TRIAL-15DAY-PROMO-STD0PLUS1PRO2'
	},
	{
		id: 'STORE-TRIAL-15DAY-PROMO-STD0PLUS2PRO3',
		label: '15 Day Trial - Standard 0 Plus 2 Pro 3 (sku:STORE-TRIAL-15DAY-PROMO-STD0PLUS2PRO3)',
		value: 'STORE-TRIAL-15DAY-PROMO-STD0PLUS2PRO3'
	},
	{
		id: 'STORE-TRIAL-15DAY-PROMO-STD1PLUS1PRO2',
		label: '15 Day Trial - Standard 1 Plus 1 Pro 2 (sku:STORE-TRIAL-15DAY-PROMO-STD1PLUS1PRO2)',
		value: 'STORE-TRIAL-15DAY-PROMO-STD1PLUS1PRO2'
	},
	{
		id: 'STORE-TRIAL-15DAY-STD1PLUS2PRO3',
		label: '15 Day Trial - Standard 1 Plus 2 Pro 3 (sku:STORE-TRIAL-15DAY-STD1PLUS2PRO3)',
		value: 'STORE-TRIAL-15DAY-STD1PLUS2PRO3'
	},
	{
		id: 'STORE-TRIAL-30DAY-SQUARE',
		label: '30 Day Square Trial (sku:STORE-TRIAL-30DAY-SQUARE)',
		value: 'STORE-TRIAL-30DAY-SQUARE'
	},
	{
		id: 'STORE-ENTERPRISE-TRIAL-30DAY',
		label: '30 Day Trial (sku:STORE-ENTERPRISE-TRIAL-30DAY)',
		value: 'STORE-ENTERPRISE-TRIAL-30DAY'
	},
	{
		id: 'STORE-ENTERPRISE-TRIAL-SELFSERVE-30DAY',
		label: '30 Day Trial (sku:STORE-ENTERPRISE-TRIAL-SELFSERVE-30DAY)',
		value: 'STORE-ENTERPRISE-TRIAL-SELFSERVE-30DAY'
	},
	{
		id: 'STORE-TRIAL-30DAY',
		label: '30 Day Trial (sku:STORE-TRIAL-30DAY)',
		value: 'STORE-TRIAL-30DAY'
	},
	{
		id: 'STORE-TRIAL-30DAY-PROMO-1MO',
		label: '30 Day Trial - 1 mo Free (sku:STORE-TRIAL-30DAY-PROMO-1MO)',
		value: 'STORE-TRIAL-30DAY-PROMO-1MO'
	},
	{
		id: 'STORE-TRIAL-30DAY-PROMO-2MO',
		label: '30 Day Trial - 2 mo Free (sku:STORE-TRIAL-30DAY-PROMO-2MO)',
		value: 'STORE-TRIAL-30DAY-PROMO-2MO'
	},
	{
		id: 'STORE-TRIAL-30DAY-PROMO-3MO',
		label: '30 Day Trial - 3 mo Free (sku:STORE-TRIAL-30DAY-PROMO-3MO)',
		value: 'STORE-TRIAL-30DAY-PROMO-3MO'
	},
	{
		id: 'STORE-TRIAL-30DAY-PROMO-4MO',
		label: '30 Day Trial - 4 mo Free (sku:STORE-TRIAL-30DAY-PROMO-4MO)',
		value: 'STORE-TRIAL-30DAY-PROMO-4MO'
	},
	{
		id: 'STORE-TRIAL-30DAY-PROMO-6MO',
		label: '30 Day Trial - 6 mo Free (sku:STORE-TRIAL-30DAY-PROMO-6MO)',
		value: 'STORE-TRIAL-30DAY-PROMO-6MO'
	},
	{
		id: 'STORE-TRIAL-30DAY-PROMO-STD0PLUS1PRO2',
		label: '30 Day Trial - Standard 0 Plus 1 Pro 2 (sku:STORE-TRIAL-30DAY-PROMO-STD0PLUS1PRO2)',
		value: 'STORE-TRIAL-30DAY-PROMO-STD0PLUS1PRO2'
	},
	{
		id: 'STORE-TRIAL-30DAY-PROMO-STD0PLUS2PRO3',
		label: '30 Day Trial - Standard 0 Plus 2 Pro 3 (sku:STORE-TRIAL-30DAY-PROMO-STD0PLUS2PRO3)',
		value: 'STORE-TRIAL-30DAY-PROMO-STD0PLUS2PRO3'
	},
	{
		id: 'STORE-TRIAL-30DAY-PROMO-STD1PLUS1PRO2',
		label: '30 Day Trial - Standard 1 Plus 1 Pro 2 (sku:STORE-TRIAL-30DAY-PROMO-STD1PLUS1PRO2)',
		value: 'STORE-TRIAL-30DAY-PROMO-STD1PLUS1PRO2'
	},
	{
		id: 'STORE-TRIAL-30DAY-PROMO-STD1PLUS2PRO3',
		label: '30 Day Trial - Standard 1 Plus 2 Pro 3 (sku:STORE-TRIAL-30DAY-PROMO-STD1PLUS2PRO3)',
		value: 'STORE-TRIAL-30DAY-PROMO-STD1PLUS2PRO3'
	},
	{
		id: 'STORE-TRIAL-45DAY',
		label: '45 Day Trial (sku:STORE-TRIAL-45DAY)',
		value: 'STORE-TRIAL-45DAY'
	},
	{
		id: 'STORE-TRIAL-60DAY',
		label: '60 Day Trial (sku:STORE-TRIAL-60DAY)',
		value: 'STORE-TRIAL-60DAY'
	},
	{
		id: 'STORE-LAUNCHBAY',
		label: 'Launchbay Trial Store (sku:STORE-LAUNCHBAY)',
		value: 'STORE-LAUNCHBAY'
	},
	{
		id: 'STORE-QE-MONTHLY',
		label: 'QE Monthly Store (sku:STORE-QE-MONTHLY)',
		value: 'STORE-QE-MONTHLY'
	}
]
