import dynamic from 'next/dynamic'

import { Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./ThemesSearch').then(({ ThemesSearch }) => ThemesSearch)))
	),
	{
		type: 'themes-search-results',
		label: 'Contentful/Marketplace/Themes/Themes Search',
		props: {
			className: Style(),
			searchInputPlaceholder: TextInput({
				label: 'Search Input Placeholder',
				defaultValue: 'Search for...'
			})
		}
	}
)
