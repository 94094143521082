import dynamic from 'next/dynamic'

import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./AppDetailsSidebar').then(({ AppDetailsSidebar }) => AppDetailsSidebar))
		)
	),
	{
		type: 'marketplace-app-details-sidebar',
		label: 'Contentful/Marketplace/Apps/App Details Sidebar'
	}
)
