import dynamic from 'next/dynamic'

import {
	Checkbox,
	Link,
	List,
	Number,
	Select,
	Shape,
	Style,
	TextInput
} from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./ButtonGroup').then(({ ButtonGroup }) => ButtonGroup)))
	),
	{
		type: 'button-group',
		label: 'Button Group',
		props: {
			className: Style(),
			buttons: List({
				label: 'Buttons',
				type: Shape({
					type: {
						text: TextInput({ label: 'Text', defaultValue: 'Button text', selectAll: true }),
						link: Link({ label: 'Link' }),
						variant: Select({
							label: 'Variant',
							options: [
								{ value: 'filled', label: 'Primary' },
								{ value: 'outlined', label: 'Secondary' },
								{ value: 'subtle', label: 'Tertiary' }
							],
							defaultValue: 'filled'
						}),
						color: Select({
							label: 'Color',
							options: [
								{ value: 'primary', label: 'Blue' },
								{ value: 'secondary', label: 'Black' },
								{ value: 'tertiary', label: 'White' }
							],
							defaultValue: 'primary'
						}),
						size: Select({
							label: 'Size',
							options: [
								{ value: 'default', label: 'Default' },
								{ value: 'large', label: 'Large' }
							],
							defaultValue: 'default'
						}),
						showIcon: Checkbox({ label: 'Show icon?', defaultValue: true })
					}
				}),
				getItemLabel(button) {
					return button?.text || 'Button text'
				}
			}),
			alignment: Select({
				label: 'Alignment',
				options: [
					{ value: 'left', label: 'Left' },
					{ value: 'center', label: 'Center' },
					{ value: 'right', label: 'Right' }
				],
				defaultValue: 'left'
			}),
			alignmentMobile: Select({
				label: 'Mobile Alignment',
				options: [
					{ value: 'left', label: 'Left' },
					{ value: 'center', label: 'Center' },
					{ value: 'right', label: 'Right' }
				],
				defaultValue: 'left'
			}),
			buttonGap: Number({
				label: 'Gap',
				defaultValue: 16,
				step: 4,
				suffix: 'px',
				selectAll: true
			})
		}
	}
)
