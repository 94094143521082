import dynamic from 'next/dynamic'

import {
	Checkbox,
	Color,
	Image,
	Link,
	List,
	Number,
	Select,
	Shape,
	Style,
	TextArea,
	TextInput
} from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	cards: List({
		label: 'Cards',
		type: Shape({
			type: {
				imageSrc: Image({ label: 'Image', format: Image.Format.WithDimensions }),
				imageAlt: TextInput({
					label: 'Alt text',
					defaultValue: 'Image',
					selectAll: true
				}),
				heading: TextInput({
					label: 'Heading',
					defaultValue: 'Case study',
					selectAll: true
				}),
				title: TextInput({
					label: 'Title',
					defaultValue: 'This is a title',
					selectAll: true
				}),
				text: TextArea({
					label: 'Text',
					defaultValue:
						'Lorem ipsum dolor sit amet, vix id suas harum tacimates, enim doming prompta vim antial.',
					selectAll: true
				}),
				linkText: TextInput({
					label: 'Link text',
					defaultValue: 'Read more',
					selectAll: true
				}),
				linkURL: Link({ label: 'On click' })
			}
		}),
		getItemLabel(item) {
			return item?.title ?? 'This is a title'
		}
	}),
	bgColor: Color({ label: 'Card color', defaultValue: '#121118' }),
	variant: Select({
		label: 'Text color',
		options: [
			{ value: 'light', label: 'White' },
			{ value: 'dark', label: 'Black' }
		],
		defaultValue: 'light'
	}),
	itemsShown: Number({ label: 'Items shown (desktop)', defaultValue: 4, selectAll: true }),
	skip: Number({ label: 'Desktop arrows move by', defaultValue: 4, selectAll: true }),
	loop: Checkbox({ label: 'Loop', defaultValue: true }),
	autoplay: Number({
		label: 'Autoplay',
		defaultValue: 0,
		step: 0.1,
		suffix: 's',
		selectAll: true
	})
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./CardCarousel').then(({ CardCarousel }) => CardCarousel)))
	),
	{
		type: 'card-carousel',
		label: 'Card Carousel',
		props
	}
)
