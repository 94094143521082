import dynamic from 'next/dynamic'

import { Combobox, Number, Select } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { getFieldOptions } from '@/lib/contentful/utils'
import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./AppCollectionImage').then(({ AppCollectionImage }) => AppCollectionImage))
		)
	),
	{
		type: 'marketplace-app-collection-image',
		label: 'Contentful/Marketplace/Apps/App Collection Image',
		props: {
			fieldPath: Combobox({
				label: 'Field',
				getOptions: () => [
					{
						id: 'thumbnail',
						label: 'Thumbnail',
						value: 'thumbnail'
					}
				]
			}),
			alt: Combobox({
				label: 'Alt',
				async getOptions(query) {
					return getFieldOptions({
						type: 'AppCollection',
						filter: (name) => name === 'String',
						query
					})
				}
			}),
			width: Number({
				label: 'Width',
				placeholder: 'Image width in pixels',
				defaultValue: 400
			}),
			height: Number({
				label: 'Height',
				placeholder: 'Image height in pixels',
				defaultValue: 225
			}),
			imageStyle: Select({
				label: 'Image style',
				options: [
					{ value: 'object-cover', label: 'Cover image to dimensions' },
					{ value: 'object-contain', label: 'Contain image within dimensions' }
				],
				defaultValue: 'object-cover'
			})
		}
	}
)
