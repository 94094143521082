import dynamic from 'next/dynamic'

import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Breadcrumbs/Breadcrumbs.makeswift'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./GlossaryBreadcrumbs').then(({ GlossaryBreadcrumbs }) => GlossaryBreadcrumbs))
		)
	),
	{
		type: 'glossary-breadcrumbs',
		label: 'Contentful/Glossary/Glossary Breadcrumbs',
		props
	}
)
