import dynamic from 'next/dynamic'

import { Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./AppMediaCarousel').then(({ AppMediaCarousel }) => AppMediaCarousel))
		)
	),
	{
		type: 'marketplace-app-media-carousel',
		label: 'Contentful/Marketplace/Apps/App Media Carousel',
		props: {
			className: Style()
		}
	}
)
