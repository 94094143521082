import dynamic from 'next/dynamic'

import { Link, Slot, Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	link: Link({ label: 'Link' }),
	className: Style({
		properties: [Style.Width, Style.Margin, Style.Padding, Style.Border, Style.BorderRadius]
	}),
	children: Slot()
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./LinkBox').then(({ LinkBox }) => LinkBox)))
	),
	{ type: 'LinkBox', label: 'LinkBox', props }
)
