import dynamic from 'next/dynamic'

import { Combobox, Shape, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulButton/ContentfulButton.makeswift'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./ArticleButton').then(({ ArticleButton }) => ArticleButton)))
	),
	{
		type: 'article-button',
		label: 'Contentful/Article/Article Button',
		props: {
			...props,
			overrides: Shape({
				type: {
					text: TextInput({
						label: 'Text Path Override',
						defaultValue: 'interstitialCta.displayText',
						selectAll: true
					}),
					url: TextInput({
						label: 'URL Path Override',
						defaultValue: 'interstitialCta.link.url',
						selectAll: true
					})
				}
			}),
			textFieldPath: Combobox({
				label: 'Text Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'Article',
						filter: (name) => name === 'String',
						query
					})
				}
			}),
			urlFieldPath: Combobox({
				label: 'URL Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'Article',
						filter: (name) => name === 'String',
						query
					})
				}
			})
		}
	}
)
