import dynamic from 'next/dynamic'

import { Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./CaseStudyFeed').then(({ CaseStudyFeed }) => CaseStudyFeed)))
	),
	{
		type: 'case-study-feed',
		label: 'Contentful/Case Study/Case Study Feed',
		props: {
			className: Style(),
			cardCtaText: TextInput({
				label: 'Case Study CTA Text',
				defaultValue: 'Read story'
			}),
			loadMoreCtaText: TextInput({
				label: 'Load More CTA Text',
				defaultValue: 'See more'
			})
		}
	}
)
