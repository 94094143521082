import dynamic from 'next/dynamic'

import { MakeswiftComponentType } from '@makeswift/runtime/components'
import { Checkbox, Link, Select, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	children: TextInput({ label: 'Text', defaultValue: 'Button text', selectAll: true }),
	link: Link({ label: 'Link' }),
	variant: Select({
		label: 'Variant',
		options: [
			{ value: 'filled', label: 'Primary' },
			{ value: 'outlined', label: 'Secondary' },
			{ value: 'subtle', label: 'Tertiary' }
		],
		defaultValue: 'filled'
	}),
	color: Select({
		label: 'Color',
		options: [
			{ value: 'primary', label: 'Blue' },
			{ value: 'secondary', label: 'Black' },
			{ value: 'tertiary', label: 'White' }
		],
		defaultValue: 'primary'
	}),
	size: Select({
		label: 'Size',
		options: [
			{ value: 'small', label: 'Small' },
			{ value: 'default', label: 'Default' },
			{ value: 'large', label: 'Large' }
		],
		defaultValue: 'default'
	}),
	showIcon: Checkbox({ label: 'Show icon', defaultValue: true }),
	fullWidth: Checkbox({ label: 'Full width', defaultValue: false }),
	className: Style({ properties: [Style.Margin] })
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./Button').then(({ Button }) => Button)))
	),
	{
		type: MakeswiftComponentType.Button,
		label: 'Button',
		props
	}
)
