import { ReactRuntime } from '@makeswift/runtime/react'

export const runtime = new ReactRuntime({
	breakpoints: {
		mobile: { width: 640, viewport: 390, label: 'S' },
		tablet: { width: 768, viewport: 765, label: 'M' },
		laptop: { width: 1024, viewport: 1000, label: 'L' },
		external: { width: 1280, label: 'XL' },
		tv: { width: 1536, label: '2XL' }
	}
})
