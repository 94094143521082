import dynamic from 'next/dynamic'

import { Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

const props = {
	className: Style()
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./AppReviews').then(({ AppReviews }) => AppReviews)))
	),
	{
		type: 'marketplace-app-reviews',
		label: 'Contentful/Marketplace/Apps/App Reviews',
		props
	}
)
