import { Checkbox, Select, Style } from '@makeswift/runtime/controls'

export const props = {
	className: Style({
		properties: [Style.Margin]
	}),
	openInNewTab: Checkbox({ label: 'Open in new tab' }),
	variant: Select({
		label: 'Variant',
		options: [
			{ value: 'filled', label: 'Filled' },
			{ value: 'subtle', label: 'Subtle' },
			{ value: 'outlined', label: 'Outlined' }
		],
		defaultValue: 'filled'
	}),
	color: Select({
		label: 'Color',
		options: [
			{ value: 'primary', label: 'Primary' },
			{ value: 'secondary', label: 'Secondary' },
			{ value: 'tertiary', label: 'Tertiary' }
		],
		defaultValue: 'primary'
	}),
	size: Select({
		label: 'Size',
		options: [
			{ value: 'default', label: 'Default' },
			{ value: 'large', label: 'Large' }
		],
		defaultValue: 'default'
	}),
	showIcon: Checkbox({ label: 'Show icon?', defaultValue: true }),
	uppercase: Checkbox({ label: 'Text Uppercase?', defaultValue: false })
}
