import dynamic from 'next/dynamic'

import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '../../../../common/ContentfulBox/ContentfulBox.makeswift'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./ThemesIndustriesList').then(({ ThemesIndustriesList }) => ThemesIndustriesList)
			)
		)
	),
	{
		type: 'marketplace-themes-industries-list',
		label: 'Contentful/Marketplace/Themes/Themes Industries List',
		props: {
			...props
		}
	}
)
