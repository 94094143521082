import dynamic from 'next/dynamic'

import { Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./ArticleParentFeedGrid').then(({ ArticleParentFeedGrid }) => ArticleParentFeedGrid)
			)
		)
	),
	{
		type: 'article-parent-feed-grid',
		label: 'Contentful/Article/Article Parent Feed Grid',
		props: {
			className: Style()
		}
	}
)
