import dynamic from 'next/dynamic'

import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/Breadcrumbs/Breadcrumbs.makeswift'

const { ...rest } = props

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./PersonBreadcrumbs').then(({ PersonBreadcrumbs }) => PersonBreadcrumbs))
		)
	),
	{
		type: 'person-breadcrumbs',
		label: 'Contentful/Person/Person Breadcrumbs',
		props: rest
	}
)
