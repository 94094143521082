import dynamic from 'next/dynamic'

import { Number, Select, Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./EventFeed').then(({ EventFeed }) => EventFeed)))
	),
	{
		type: 'event-feed',
		label: 'Contentful/Event/Event Feed',
		props: {
			className: Style(),
			type: Select({
				label: 'Type',
				options: [
					{ value: 'Regular', label: 'Regular' },
					{ value: 'Featured', label: 'Featured' }
				],
				defaultValue: 'Regular'
			}),
			count: Number({
				label: 'Amount of Events',
				defaultValue: 20,
				step: 1,
				min: 1,
				max: 100,
				selectAll: true
			})
		}
	}
)
