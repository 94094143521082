import dynamic from 'next/dynamic'

import { Checkbox, Image, Number, RichText, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	gradientBorder: Checkbox({ label: 'Show Border Badge', defaultValue: false }),
	gradientBadgeText: TextInput({
		label: 'Badge Text',
		defaultValue: 'NEW'
	}),
	imageSrc: Image({
		label: 'Logo',
		format: Image.Format.WithDimensions
	}),
	imageAlt: TextInput({ label: 'Image Alt' }),
	imageWidth: Number({ step: 1, label: 'Image Width', defaultValue: 100, suffix: 'px' }),
	text: RichText()
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./GradientBorderCard').then(({ GradientBorderCard }) => GradientBorderCard))
		)
	),
	{ type: 'GradientBorderCard', label: 'Gradient Border Card', props }
)
