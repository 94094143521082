import dynamic from 'next/dynamic'

import { Combobox, Select } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { getFieldOptions } from '../../../../../lib/contentful/utils'
import { props } from '../../../common/ContentfulRichText/ContentfulRichText.makeswift'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(import('./ResourceRichText').then(({ ResourceRichText }) => ResourceRichText))
		)
	),
	{
		type: 'resource-rich-text',
		label: 'Contentful/Resource/Resource Rich Text',
		props: {
			...props,
			fontSize: Select({
				label: 'Font size',
				options: [
					{ value: '', label: 'Default' },
					{ value: 'prose-p:text-base', label: 'Base' },
					{ value: 'prose-p:text-lg', label: 'Large' },
					{ value: 'prose-p:text-[34px]', label: 'Heading' }
				],
				defaultValue: ''
			}),
			fontWeight: Select({
				label: 'Font Weight',
				options: [
					{ value: '', label: 'Base' },
					{ value: 'prose-p:font-light', label: '300' },
					{ value: 'prose-p:font-normal', label: '400' },
					{ value: 'prose-p:font-medium', label: '500' },
					{ value: 'prose-p:font-bold', label: '700' }
				],
				defaultValue: ''
			}),
			lineHeight: Select({
				label: 'Line Height',
				options: [
					{ value: '', label: 'Base' },
					{ value: 'prose-p:leading-[26px]', label: '26px' },
					{ value: 'prose-p:leading-[27px]', label: '27px' },
					{ value: 'prose-p:leading-[29px]', label: '29px' },
					{ value: 'prose-p:leading-[44px]', label: '44px' }
				],
				defaultValue: ''
			}),
			color: Select({
				label: 'Color',
				options: [
					{ value: '', label: 'Base' },
					{ value: 'prose-p:text-black-100', label: 'Black' },
					{ value: 'prose-p:text-white', label: 'White' },
					{ value: 'prose-p:text-gray-700', label: 'Gray' }
				],
				defaultValue: ''
			}),
			fieldPath: Combobox({
				label: 'Field',
				async getOptions(query) {
					return getFieldOptions({
						type: 'Resource',
						filter: (name) => name === 'RichText',
						query
					})
				}
			})
		}
	}
)
