import dynamic from 'next/dynamic'

import { TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { props } from '@/components/CardCarousel/CardCarousel.makeswift'

const { ...rest } = props

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./ArticleChildrenCarousel').then(
					({ ArticleChildrenCarousel }) => ArticleChildrenCarousel
				)
			)
		)
	),
	{
		type: 'article-children-carousel',
		label: 'Contentful/Article/Article Children Carousel',
		props: {
			...rest,
			ctaText: TextInput({
				label: 'CTA Text',
				defaultValue: 'Read Article',
				selectAll: true
			})
		}
	}
)
