import { useRouter } from 'next/router'

import { Document } from '@contentful/rich-text-types'
import stringify from 'safe-stable-stringify'

import {
	ArticleBodyLinks,
	ArticleBreadcrumbsFragment,
	Asset,
	AssetExternal,
	BasicContentCard,
	BlogPostBodyLinks,
	Category,
	CategoryBreadcrumbsFragment,
	RichTextWrapper,
	TypeFragment,
	TypesDocument,
	__Field
} from '@/generated/contentful'

import { client } from '@/lib/contentful/client'

export type ResolvedField = { data: unknown } | { error: string }

const isRichTextField = (fieldType: TypeFragment) =>
	fieldType.fields?.find((f) => f.name === 'json') &&
	fieldType.fields?.find((f) => f.name === 'links')

type FieldPath = { label: string; path: string; type?: string | null }

function flattenFields(
	root: TypeFragment,
	fields: FieldPath[] = [],
	path: string[] = []
): FieldPath[] {
	if (!root.fields) return fields

	root.fields.forEach((field) => {
		const newPath = [...path, field.name]

		fields.push({
			label: [field.name, ...path.slice().reverse()].join(' < '),
			path: newPath.join('.'),
			type: isRichTextField(field.type) ? 'RichText' : field.type.name
		})

		flattenFields(field.type, fields, newPath)
	})

	return fields
}

export async function getFieldOptions({
	type,
	filter,
	query
}: {
	type:
		| 'BlogPost'
		| 'Article'
		| 'Category'
		| 'Press'
		| 'Showcase'
		| 'Person'
		| 'MarketplaceApp'
		| 'AppCollection'
		| 'AppTier'
		| 'MarketplaceTheme'
		| 'CaseStudy'
		| 'Resource'
		| 'Glossary'
	filter?: (type?: string | null) => boolean
	query: string
}): Promise<{ id: string; label: string; value: string }[]> {
	const data = await client().request(TypesDocument, { name: type })

	if (!data.__type) return []

	return flattenFields(data.__type)
		.filter((field) => (filter ? filter(field.type) : true))
		.filter((field) => field.label.toLowerCase().includes(query.toLowerCase()))
		.map((field) => ({ id: field.path, label: field.label, value: field.path }))
}

export function serializeCacheKey(key: string, params: Record<string, any> = {}): string {
	const orderedParams = Object.keys(params)
		.sort()
		.reduce(
			(acc, key) => ({
				...acc,
				[key]: params[key]
			}),
			{}
		)

	return `${key}:${stringify(orderedParams)}`
}

export function resolveParentCategories(
	category: CategoryBreadcrumbsFragment,
	parentCategories: CategoryBreadcrumbsFragment[] = []
): CategoryBreadcrumbsFragment[] {
	const categories = parentCategories

	if (!category.parentCategory) return categories

	return resolveParentCategories(category.parentCategory, [...categories, category.parentCategory])
}

export function resolveParentArticles(
	article: ArticleBreadcrumbsFragment,
	parentArticles: ArticleBreadcrumbsFragment[] = []
): ArticleBreadcrumbsFragment[] {
	const articles = parentArticles

	if (!article.parentArticle) return articles

	return resolveParentArticles(article.parentArticle, [...articles, article.parentArticle])
}

export function resolveArticleSlug(article: ArticleBreadcrumbsFragment) {
	const parentArticles = resolveParentArticles(article)

	return [article, ...parentArticles.slice().reverse()]
		.map(({ slug }) => slug)
		.filter(Boolean)
		.join('/')
}

export function useSlug(): string | undefined {
	const router = useRouter()

	// Note - must include trailing slash for template pages to work in Makeswift
	switch (router.asPath) {
		case '/blog/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_BLOG_SLUG
		case '/blog/category/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_BLOG_CATEGORY_SLUG
		case '/blog/author/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_BLOG_AUTHOR_SLUG
		case '/case-study/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_CASE_STUDY_SLUG
		case '/company/leaders/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_COMPANY_LEADER_SLUG
		case '/press/releases/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_PRESS_RELEASE_SLUG
		case '/articles/parent/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_PARENT_ARTICLE_SLUG
		case '/articles/child/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_CHILD_ARTICLE_SLUG
		case '/apps/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_APP_SLUG
		case '/apps/collections/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_APP_COLLECTION_SLUG
		case '/apps/tiers/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_APP_TIER_SLUG
		case '/theme/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_THEME_SLUG
		case '/showcase/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_SHOWCASE_SLUG
		case '/glossary/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_GLOSSARY_SLUG
		case '/glossary/category/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_GLOSSARY_CATEGORY_SLUG
		case '/resources/webinars/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_WEBINARS_SLUG
		case '/resources/webinars-ty/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_WEBINARS_TY_SLUG
		case '/resources/reports/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_REPORTS_SLUG
		case '/resources/reports-ty/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_REPORTS_TY_SLUG
		case '/resources/guides/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_GUIDES_SLUG
		case '/resources/guides-ty/__template__/':
			return process.env.NEXT_PUBLIC_MAKESWIFT_TEMPLATE_GUIDES_TY_SLUG
	}

	if (router.query.slug && Array.isArray(router.query.slug)) {
		return router.query.slug.slice(-1)[0]
	}

	if (router.query.slug?.length) {
		return router.query.slug
	}

	// Using this instead of router.query.slug in order to handle base routes in catch all [[...slug]] where slug is undefined
	return router.asPath.split('?').at(0)?.split('/').filter(Boolean)?.at(-1)
}

export function resolvePath<T extends string>(
	path: string | Array<T>,
	obj?: { [key: string]: any } | null,
	separator: string = '.'
): any {
	const properties = Array.isArray(path) ? path : path.split(separator)

	return properties.reduce((prev, curr) => prev?.[curr], obj ?? {})
}

export function getBaseUrl(locale?: string) {
	if (process.env.VERCEL_ENV === 'staging' || process.env.NEXT_PUBLIC_VERCEL_ENV === 'staging') {
		switch (locale) {
			case 'en-AU':
				return 'https://www-com-au.staging.zone'
			case 'en-GB':
				return 'https://www-co-uk.staging.zone'
			case 'fr-FR':
				return 'https://www-fr.staging.zone'
			case 'nl-NL':
				return 'https://www-nl.staging.zone'
			case 'it-IT':
				return 'https://www-it.staging.zone'
			case 'es-ES':
				return 'https://www-es.staging.zone'
			case 'es-MX':
				return 'https://www-mx.staging.zone'
			case 'de-DE':
				return 'https://www-de.staging.zone'
			case 'nb-NO':
				return 'https://www-no.staging.zone'
			case 'no-NO':
				return 'https://www-no.staging.zone'
			case 'da-DK':
				return 'https://www-dk.staging.zone'
			case 'sv-SE':
				return 'https://www-se.staging.zone'
			case 'de-AT':
				return 'https://www-at.staging.zone'
			case 'en-SG':
				return 'https://www-sg.staging.zone'
			default:
				return 'https://www.staging.zone'
		}
	}

	switch (locale) {
		case 'en-AU':
			return 'https://www.bigcommerce.com.au'
		case 'en-GB':
			return 'https://www.bigcommerce.co.uk'
		case 'fr-FR':
			return 'https://www.bigcommerce.fr'
		case 'nl-NL':
			return 'https://www.bigcommerce.nl'
		case 'it-IT':
			return 'https://www.bigcommerce.it'
		case 'es-ES':
			return 'https://www.bigcommerce.es'
		case 'es-MX':
			return 'https://www.bigcommerce.mx'
		case 'de-DE':
			return 'https://www.bigcommerce.de'
		case 'nb-NO':
			return 'https://www.bigcommerce.no'
		case 'no-NO':
			return 'https://www.bigcommerce.no'
		case 'da-DK':
			return 'https://www.bigcommerce.dk'
		case 'sv-SE':
			return 'https://www.bigcommerce.se'
		case 'de-AT':
			return 'https://www.bigcommerce.at'
		case 'en-SG':
			return 'https://www.bigcommerce.sg'
		default:
			return 'https://www.bigcommerce.com'
	}
}

export const isAsset = (field: any): field is Asset => field?.__typename === 'Asset'

export const isAssetExternal = (entry: any): entry is AssetExternal =>
	entry?.__typename === 'AssetExternal'

export const isRichText = (
	field: any
): field is { json: Document; links: ArticleBodyLinks | BlogPostBodyLinks } =>
	field?.json?.nodeType === 'document'

export const isCategory = (entry: any): entry is Category => entry.__typename === 'Category'

export const isDateString = (field: any): field is string =>
	typeof field === 'string' && !isNaN(Date.parse(field))

export const isBasicContentCard = (entry: unknown): entry is BasicContentCard =>
	typeof entry === 'object' &&
	entry !== null &&
	'__typename' in entry &&
	entry.__typename === 'BasicContentCard'

export const isRichTextWrapper = (entry: unknown): entry is RichTextWrapper =>
	typeof entry === 'object' &&
	entry !== null &&
	'__typename' in entry &&
	entry.__typename === 'RichTextWrapper'
