import dynamic from 'next/dynamic'

import { Color, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./ShareLinks').then(({ ShareLinks }) => ShareLinks)))
	),
	{
		type: 'share-links',
		label: 'Share Links',
		props: {
			className: Style(),
			heading: TextInput({
				label: 'Heading',
				defaultValue: 'Share this article',
				selectAll: true
			}),
			title: TextInput({
				label: 'Title',
				defaultValue: 'Title',
				selectAll: true
			}),
			url: TextInput({
				label: 'URL',
				defaultValue: 'https://www.bigcommerce.com',
				selectAll: true
			}),
			color: Color({ label: 'Icon color', defaultValue: '#0D52FF' })
		}
	}
)
