import dynamic from 'next/dynamic'

import { Link, List, Shape, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./LinkSelect').then(({ LinkSelect }) => LinkSelect)))
	),
	{
		type: 'link-select',
		label: 'Link Select',
		props: {
			className: Style(),
			buttonText: TextInput({
				label: 'Button text',
				defaultValue: 'Select an option',
				selectAll: true
			}),
			links: List({
				label: 'Links',
				type: Shape({
					type: {
						text: TextInput({ label: 'Text', defaultValue: 'Link', selectAll: true }),
						link: Link({ label: 'On click' })
					}
				}),
				getItemLabel(link) {
					return link?.text || 'Link'
				}
			})
		}
	}
)
