import dynamic from 'next/dynamic'

import { Checkbox, Slot, Style } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./WebinarSpeakers').then(({ WebinarSpeakers }) => WebinarSpeakers)))
	),
	{
		type: 'resource-webinar-speakers',
		label: 'Contentful/Resource/Webinar Speakers',
		props: {
			containerStyle: Style({ properties: [Style.Width, Style.Margin, Style.Padding] }),
			className: Style(),
			isHeaderActive: Checkbox({ label: 'Include header', defaultValue: false }),
			children: Slot()
		}
	}
)
