import dynamic from 'next/dynamic'

import { Link, List, Shape, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	title: TextInput({ label: 'Title', defaultValue: 'By Use Case', selectAll: true }),
	links: List({
		label: 'Links',
		type: Shape({
			type: {
				link: Link({ label: 'Link' }),
				label: TextInput({ label: 'Label', defaultValue: 'Link', selectAll: true })
			}
		}),
		getItemLabel(item) {
			return item?.label ?? 'Link'
		}
	}),
	fallbackLabel: TextInput({ label: 'Fallback label', defaultValue: 'Select option' })
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./HeaderSubnav').then(({ HeaderSubnav }) => HeaderSubnav)))
	),
	{
		type: 'HeaderSubnav',
		label: 'HeaderSubnav',
		props
	}
)
