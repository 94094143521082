import { Color, Link, Number, Select, Style } from '@makeswift/runtime/controls'

export const props = {
	className: Style({
		properties: [Style.Margin, Style.Width, Style.TextStyle]
	}),
	color: Color({ label: 'Color', defaultValue: '#121118' }),
	alignment: Select({
		label: 'Alignment',
		options: [
			{ value: 'left', label: 'Left' },
			{ value: 'center', label: 'Center' },
			{ value: 'right', label: 'Right' },
			{ value: 'justify', label: 'Justify' }
		]
	}),
	lineHeight: Number({
		label: 'Line height',
		min: 0,
		max: 2,
		step: 0.1
	}),
	link: Link({ label: 'Link' })
}
