import dynamic from 'next/dynamic'

import { Combobox, List } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { getFieldOptions } from '@/lib/contentful/utils'
import { runtime } from '@/lib/makeswift/runtime'

import { props } from '../../../../common/ContentfulBox/ContentfulBox.makeswift'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./AppBox').then(({ AppBox }) => AppBox)))
	),
	{
		type: 'marketplace-app-box',
		label: 'Contentful/Marketplace/Apps/App Box',

		props: {
			...props,
			requiredFieldPaths: List({
				label: 'Required fields (Required to show)',
				type: Combobox({
					label: 'Field',
					async getOptions(query: string) {
						return getFieldOptions({
							type: 'MarketplaceApp',
							query
						})
					}
				}),
				getItemLabel(item) {
					return item?.label ?? 'Untitled'
				}
			}),
			blockedFieldPaths: List({
				label: 'Blocked fields (Hides if present)',
				type: Combobox({
					label: 'Field',
					async getOptions(query: string) {
						return getFieldOptions({
							type: 'MarketplaceApp',
							query
						})
					}
				}),
				getItemLabel(item) {
					return item?.label ?? 'Untitled'
				}
			})
		}
	}
)
