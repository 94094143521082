import dynamic from 'next/dynamic'

import { Number, Select, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import {
	DEFAULT_COUNT,
	FEATURED_CONTENT_DEFAULT_FEATURED_TAG,
	FeaturedContentOrderBy,
	FeaturedContentType
} from './FeaturedContentBlock'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() =>
			patch(
				import('./FeaturedContentBlock').then(({ FeaturedContentBlock }) => FeaturedContentBlock)
			)
		)
	),
	{
		type: 'featured-content',
		label: 'Contentful/Featured Content',
		props: {
			className: Style(),
			tag: TextInput({
				label: 'Contentful tag',
				defaultValue: FEATURED_CONTENT_DEFAULT_FEATURED_TAG,
				selectAll: true
			}),
			layout: Select({
				label: 'Layout',
				options: [
					{ value: FeaturedContentType.WithImage, label: 'With Image' },
					{ value: FeaturedContentType.WithoutImage, label: 'Without Image' }
				],
				defaultValue: FeaturedContentType.WithImage
			}),
			count: Number({
				label: 'Shown contents',
				defaultValue: DEFAULT_COUNT,
				step: 1,
				min: 1,
				max: 100
			}),
			orderBy: Select({
				label: 'Order by',
				options: [
					{ value: FeaturedContentOrderBy.SysPublishedAtAsc, label: 'Published at asc' },
					{ value: FeaturedContentOrderBy.SysPublishedAtDesc, label: 'Published at desc' },
					{ value: FeaturedContentOrderBy.SysFirstPublishedAtAsc, label: 'First published asc' },
					{ value: FeaturedContentOrderBy.SysFirstPublishedAtDesc, label: 'First published desc' },
					{ value: FeaturedContentOrderBy.SysPublishedVersionAsc, label: 'Published version asc' },
					{
						value: FeaturedContentOrderBy.SysPublishedVersionDesc,
						label: 'Published version desc'
					},
					{ value: FeaturedContentOrderBy.SysIdAsc, label: 'Id asc' },
					{ value: FeaturedContentOrderBy.SysIdDesc, label: 'Id desc' }
				],
				defaultValue: FeaturedContentOrderBy.SysPublishedAtDesc
			})
		}
	}
)
